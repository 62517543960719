/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-15 15:23:21
 * @LastEditTime: 2023-11-27 17:18:38
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/service/cooper/template.js
 *
 */
import { get, post } from '@/utils/request/cooper';
import { getIds } from '@/utils/file';
import api from '@/utils/request/api/CooperApi';
import { message } from 'antd';
import { intl } from 'di18n-react';

const docUrlMap = {
  DOC: '/docs/document/:resourceId',
  SHEET: '/docs/sheet/:resourceId',
};

const docTypeMap = {
  DOC: 'shimoWord',
  SHEET: 'shimoExcel',
};


const FILETYPE = {
  0: 'SHEET',
  1: 'DOC',
};

export async function getTemplateType(fileType) {
  const res = await get(api.QUERY_TEMPLATE_TYPE.replace(':fileType', fileType), {
    headers: {
      'X-App-Id': 2,
    },
  });
  return res;
}

export async function getTemplateList(params) {
  const res = await get(api.QUERY_TEMPLATE_LIST.replace(':fileType', params.fileType), {
    params: {
      ...params,
      thumbnailSize: 'STANDARD',
    },
    headers: {
      'X-App-Id': 2,
    },
  });
  return res;
}


export async function getAllTypeTemplateList(params) {
  const res = await get(api.QUERY_ALL_TYPE_LIST.replace(':fileType', params.fileType), {
    params: {
      ...params,
      thumbnailSize: 'STANDARD',
    },
    headers: {
      'X-App-Id': 2,
    },
  });
  return res;
}

export async function createDocByTemplate({
  templateId,
  fileType,
}) {
  const { parentId, teamId } = getIds();
  return post(api.SPACE_DOC.replace(':teamId', teamId), {
    template_id: templateId,
    file_type: docTypeMap[FILETYPE[fileType]],
    parent_id: parentId,
    team_id: teamId,
  }, {
    headers: {
      'X-App-Id': 2,
    },
    keepSilent: true,
  }).then((res) => {
    let newUrl = docUrlMap[FILETYPE[fileType]].replace(':resourceId', res.resourceId);
    return newUrl;
  }).catch((err) => {
    if (err.errorCode === 301082) {
      message.error(intl.t('您没有权限！请联系空间管理员添加上传权限。'))
    } else {
      message.error(err.message)
    }
    return Promise.reject(err);
  })
}

