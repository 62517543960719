/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-26 15:08:35
 * @LastEditTime: 2023-09-14 11:46:50
 * @Description: 简单描述 cooper常量配置
 * @FilePath: /knowledgeforge/src/constants/cooper.js
 *
 */
// FILE 普通文件，DIR 文件夹，COOPER_DOC 石墨文档，COOPER_SHEET 石墨表格，DI_DOC 轻文档，COOPER_SLICE 石墨幻灯片，COOPER_ANONYMOUS_DOC 石墨匿名文档，NOTE 轻笔记，FLOWCHART 流程图，DK_PAGE 知识库页面，DK_FILE 知识库普通通文件的页面，WIKI_PAGE wiki的页面
export const FileType = {
  FILE: 'FILE', // 普通文件
  DIR: 'DIR', // 文件夹
  COOPER_DOC: 'COOPER_DOC', // 协作文档
  COOPER_SHEET: 'COOPER_SHEET', // 协作表格
  COOPER_SLICE: 'COOPER_SLICE', // 石墨幻灯片
  COOPER_ANONYMOUS_DOC: 'COOPER_ANONYMOUS_DOC', // 石墨匿名文档
  SHIMO2_WORD: 'SHIMO2_WORD', // 石墨2.0 文档
  SHIMO2_EXCEL: 'SHIMO2_EXCEL', // 石墨2.0 表格
  SHIMO2_PPT: 'SHIMO2_PPT', // 石墨2.0 PPT
  DI_DOC: 'DI_DOC', // 氢文档
  NOTE: 'NOTE', // 轻笔记
  FLOWCHART: 'FLOWCHART', // 流程图
  DK_PAGE: 'DK_PAGE', // 知识库页面
  DK_FILE: 'DK_FILE', // 知识库普通通文件
  PAGE_SHARE: 'PAGE_SHARE', // 分享页面
  DK_SHARE: 'DK_SHARE', // 分享知识库
  DK_RESOURCE: 'RESOURCE',
  WIKI_PAGE: 'WIKI_PAGE', // wiki页面
  WIKI_PAGE_V2: 'WIKI',
  SPACE: 'TEAM_SPACE', // 团队空间
  SPACE_V2: 'SPACE',
  SPACE_V3: 'team',
  PERSONAL_SPACE: 'PERSONAL_SPACE', // 个人空间
  PERSONAL_SPACE_V2: 'PERSON_SPACE',
  PERSONAL_SPACE_V3: 'person',
  KNOWLEDGE: 'METIS_SPACE', // 知识库
  KNOWLEDGE_V2: 'KNOWLEDGE',
}

export const TeamTag = {
  SPACE_DEPART: 'SPACE_DEPART', // 团队标识
}

export const MOVE = 'MOVE';
export const COPY = 'COPY';
export const SAVE = 'SAVE';
export const UPLOAD = 'UPLOAD';
export const CREATE = 'CREATE';
export const DESSELECTOR = 'DESSELECTOR';

export const N_ROOT = 'ROOT';
export const N_TEAMROOT = 'TEAMROOT';
export const N_PERSONAL = 'PERSONAL';
export const N_TEAM = 'TEAM';
export const N_DIR = 'DIR';
export const N_NEWFOLD = 'NEW_FOLDER';

export const HIGH_SEP = '§§';

export const collaborativeDocumentTypes = {
  anonymousWord: 'shimoAnonymousWord',
  word: 'shimoWord',
  word2: 'SHIMO2_WORD',
  excel: 'shimoExcel',
  excel2: 'SHIMO2_EXCEL',
  slide: 'shimoPPT',
  slide2: 'SHIMO2_PPT',
  didoc: 'didocWord',
};

export const CreateType = {
  DOCUMENT: 'document',
  SHEET: 'sheet',
  SLIDE: 'slide',
  DIDOC: 'didoc',
  ANONYMOUS: 'ANONYMOUS',
  DOCUMENT2: 'DOCUMENT2',
  SHEET2: 'SHEET2',
  SLIDE2: 'SLIDE2',
}

// 团队及个人已经分开，此处重新定义二者常量
export const MY_COOPER = 9;
export const TEAM_COOPER = 10;


// 我分享的目前没有操作栏
export const SHARE_FROM_ME = 4;
export const SHARE_WITH_ME = 5;
export const STAR = 6;
export const TRASH_CAN = 7;
export const SEARCH_RESULT = 8;
export const FILE_PREVIEW = 9;

// 团队空间，展示协作的操作权限常量
export const TEAM_OPREATION_TYPE = 'teamSpace';

export const SPACE_FILE_SORT = {
  sortBy: 'display_name',
  orderAsc: 1,
}

export const PERSON_FILE_SORT = {
  sortBy: 'modify_time',
  orderAsc: 0,
}


export const SHARE_FILE_SORT = {
  sortBy: 'time',
  orderAsc: 0,
}

export const FAVORITE_FILE_SORT = {
  sortBy: 'time',
  orderAsc: 0,
}

export const HOME_FILE_TYPE_RECENT = 'coo_doc,coo_sheet,coo_ppt,coo_file'

export const HOME_FILE_TYPE_EDIT = 'coo_doc,coo_sheet,coo_ppt'

export const HOME_FILE_TYPE_RECENT_TENANT = 'all'

export const HOME_FILE_TYPE_EDIT_TENANT = 'all'
