
/**
 * 文件预览
 * 文件类型：
 *    FILE 普通文件，DIR 文件夹，COOPER_DOC 石墨文档，COOPER_SHEET 石墨表格，COOPER_SLICE 石墨幻灯片，
 *    COOPER_ANONYMOUS_DOC 石墨匿名文档，DI_DOC 氢文档，NOTE 轻笔记，FLOWCHART 流程图，DK_PAGE 知识库页面，DK_FILE 知识库普通通文件的页面，WIKI_PAGE wiki的页面
 */
import openDk from '@/components/CooperOperation/OpenDk';
import openDkPage from '@/components/CooperOperation/OpenDkPage';
import openFolder from '@/components/CooperOperation/OpenFolder';
import PreviewFile from '@/components/CooperOperation/PreviewFile';
import { FileType } from '@/constants/cooper';
import { getFlowChartUrl } from '@/service/cooper/index';
import { openNewWindow } from '@/utils/index';
import api from '@/utils/request/api/CooperApi';
import { message } from 'antd';
import { intl } from 'di18n-react';
// icon
import {
  fileDoc,
  fileExcel,
  filePPT,
  filePdf,
  fileTxt,
  fileZip,
  folder,
  pic,
  unknown,
  video,
} from '@/assets/icon/fileIcon';


export async function handleFileClick({
  fileTypeStr,
  resourceId,
  resourceName,
  knowledgeId,
  dkShareType,
  pageId,
  shareId,
  shareLink,
  markedQuickVisit,
  teamId, // 打开文件夹时
  spaceId,
  spaceType,
  quickId,
  parentId,
  shareType,
  linkUrl,
  filesList,
  relationTypeTags,
  fidx,
  hasOperation = true,
}) {
  switch (fileTypeStr) {
    // 普通文件
    case FileType.FILE:
      PreviewFile({
        resourceId,
        resourceName,
        shareId,
        shareType,
        shareLink,
        markedQuickVisit,
        teamId,
        quickId,
        objectType: fileTypeStr,
        imgPreview: false,
        spaceId,
        parentId,
        filesList,
        fidx,
        hasOperation,
        relationTypeTags,
      });
      break;

    // 文件夹
    case FileType.DIR:
      openFolder({
        resourceId, teamId, shareId, spaceType, linkUrl,
      });
      break;

    // 协作文档
    case FileType.COOPER_DOC:
      openNewWindow(`/docs/document/${resourceId}`);
      break;
    case FileType.SHIMO2_WORD:
      openNewWindow(`/docs2/document/${resourceId}`);
      break;
    case FileType.COOPER_SHEET:
      openNewWindow(`/docs/sheet/${resourceId}`);
      break;
    case FileType.SHIMO2_EXCEL:
      openNewWindow(`/docs2/sheet/${resourceId}`);
      break;
    case FileType.COOPER_SLICE:
      openNewWindow(`/docs/slide/${resourceId}`);
      break;
    case FileType.SHIMO2_PPT:
      openNewWindow(`/docs2/slide/${resourceId}`);
      break;

    case FileType.COOPER_ANONYMOUS_DOC:
      openNewWindow(`/docs/document/${resourceId}`);
      break;

    // 氢文档之后要下线啦
    case FileType.DI_DOC:
      openNewWindow(`/didoc/file/${resourceId}`);
      break;


    // 流程图
    case FileType.FLOWCHART:
      const url = await getFlowChartUrl(resourceId)
      openNewWindow(url);
      break;

    // 知识库页面
    case FileType.DK_PAGE:
      openDkPage({
        dkShareType,
        pageId,
        knowledgeId,
        shareId,
        shareLink,
      });
      break;

    case FileType.DK_FILE:
      openDkPage({
        dkShareType,
        pageId,
        knowledgeId,
        shareId,
        shareLink,
      });
      break;
    case FileType.KNOWLEDGE:
    case FileType.KNOWLEDGE_V2:
      openDk({
        knowledgeId,
      });
      break;

    case FileType.WIKI_PAGE:
    case FileType.WIKI_PAGE_V2:
      if (process.env.APP_ENV === 'prod') {
        openNewWindow(`http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=${pageId}`)
      } else {
        openNewWindow(`http://wiki-qa.intra.xiaojukeji.com/pages/viewpage.action?pageId=${pageId}`)
      }
      break;

    case FileType.SPACE:
    case FileType.SPACE_V2:
      openNewWindow(`/team-file/${teamId}`)
      break;
    case FileType.PERSONAL_SPACE:
    case FileType.PERSONAL_SPACE_V2:
      // if (resourceName.index(userInfo.userCn) < 0) {
      //   openNewWindow(`/files/${resourceId}`);
      // } else {
      //   openNewWindow('/disk');
      // }
      openNewWindow('/disk');
      break;

    default:
      break;
  }
}

// 文件类型
export const fileTypes = {
  word: 'word',
  excel: 'excel',
  txt: 'txt',
  image: 'image',
  folder: 'DIR',
  other: 'other',
};

/**
  * @params filterName
  * @return  word - text , excel - spreadsheet, ppt｜pptx - presentation , pdf - pdf , 视频 - video , 其他格式。
  * */
export const checkFileType = (filterName) => {
  let index = filterName.lastIndexOf('.');
  let fileValueSuffix = filterName.substring(index);
  if (/(.*)\.(mp4|avi|wmv|wma|3gp|mov|ogg|rmvb|rm|asf|mpg|mpeg|mpe|wmv|mkv|vob)$/.test(fileValueSuffix)) {
    return 'video';
  } if (/(.*)\.(doc|docx)$/.test(fileValueSuffix)) {
    return 'text';
  } if (/(.*)\.(xlsx|xls)$/.test(fileValueSuffix)) {
    return 'spreadsheet';
    // 待确认pptx 属于吗 presentation 类型吗？
  } if (/(.*)\.(ppt|pptx)$/.test(fileValueSuffix)) {
    return 'presentation';
  } if (/(.*)\.(pdf)$/.test(fileValueSuffix)) {
    return 'pdf';
  }
  return fileValueSuffix;
};


export const getIconByName = (name, type) => {
  if (type === fileTypes.folder) return folder;
  const lowerName = name.toLowerCase();
  if (/\.pdf$/.test(lowerName)) return filePdf;
  if (/\.docx?$/.test(lowerName)) return fileDoc;
  if (/\.pptx?$/.test(lowerName)) return filePPT;
  if (/\.xlt$|\.xlsx?$|\.xml$|\.numbers$/.test(lowerName)) return fileExcel;
  if (/\.txt$|\.md$/.test(lowerName)) return fileTxt;
  if (/\.zip$|\.tar$|\.gz$/.test(lowerName)) return fileZip;
  if (/\.mp3$/.test(lowerName)) return video;
  if (/\.mp4$|\.avi$|\.wma$|\.3gp$|\.mov$|\.ogg$|\.rmvb$/.test(lowerName)) return video;
  if (/\.jpe?g$|\.gif$|\.png$|\.svg$/.test(lowerName)) return pic;
  return unknown; // 缺unknown icon
};

export const openVideoPreview = (fileId, shareId = 0, type = 'file', file = {}) => {
  // type: 'file' | 'direct' | 'link'
  if (!fileId) {
    console.error(intl.t('文件resourceid不能为空')); // eslint-disable-line no-console
  } else {
    window.open(`/videopreview/${type}/${fileId}/${shareId}?teamId=${file.teamId}`);
  }
};


export const isVideo = (fname) => {
  return /\.mp4$|\.avi$|\.wma$|\.rmvb$|\.3gp$|\.ogg$/.test(fname.toLowerCase());
  // return /\.mp4$|\.3gp$|\.ogg$/.test(fname.toLowerCase());
};

export const isNewVideo = (fname) => {
  return /\.(mov|mkv|m4v)$/.test(fname.toLowerCase());
};

// 判断当前页面是团队空间(1)还是个人空间(2)
let pageType;
export const setPageType = (type) => {
  pageType = type;
};

export const checkPermis = (type, perm) => {
  if (pageType === 2) {
    return true;
  }
  /* eslint-disable no-bitwise */
  switch (type) {
    case 'read': {
      return Boolean(perm & 1); // 收藏
    }
    case 'write': {
      return Boolean(perm & 2); // 移动、重命名
    }
    case 'delete': {
      return Boolean(perm & 4); // 删除
    }
    case 'create': {
      return Boolean(perm & 8); // 上传、新建文件夹
    }
    case 'share': {
      return Boolean(perm & 16); // 分享
    }
    case 'download': {
      return Boolean(perm & 32); // 下载
    }
    default: {
      return false;
    }
    /* eslint-enable no-bitwise */
  }
};

export function fullScreen() {
  const el = document.documentElement;
  const rfs = el.requestFullScreen
    || el.webkitRequestFullScreen
    || el.mozRequestFullScreen
    || el.msRequestFullScreen;

  if (typeof rfs !== 'undefined' && rfs) {
    rfs.call(el);
  } else if (typeof window.ActiveXObject !== 'undefined') {
    const wscript = new window.ActiveXObject('WScript.Shell');
    if (wscript) {
      wscript.SendKeys('{F11}');
    }
  }
}


export function exitFullScreen() {
  const el = document;
  const cfs = el.cancelFullScreen
    || el.webkitCancelFullScreen
    || el.mozCancelFullScreen
    || el.exitFullScreen;

  if (typeof cfs !== 'undefined' && cfs) {
    cfs.call(el);
  } else if (typeof window.ActiveXObject !== 'undefined') {
    const wscript = new window.ActiveXObject('WScript.Shell');
    if (wscript) {
      wscript.SendKeys('{F11}');
    }
  }
}

export function getIds() {
  const { pathname } = window.location;
  // 个人空间
  if (/^\/disk\/|^\/files\//.test(pathname)) {
    const m = pathname.match(/^\/files\/(\d+)$/);
    const parentId = m ? Number(m[1]) : 0;
    return {
      parentId,
      teamId: 0,
    };
  }

  // 团队空间
  if (/^\/team-file\//.test(pathname)) {
    const m1 = pathname.match(/^\/team-file\/\d+\/(\d+)$/);
    const parentId = m1 ? Number(m1[1]) : 0;
    const m2 = pathname.match(/^\/team-file\/(\d+)/);
    const teamId = m2 ? Number(m2[1]) : 0;
    return {
      parentId,
      teamId,
    };
  }

  const params = (new URL(document.location)).searchParams;
  const teamId = params.get('teamId');

  // 其他页面
  return {
    parentId: 0,
    teamId: teamId || 0,
  };
}


export const dealDownloadRes = async (res) => {
  const { url } = res;
  jumpToUrl(url);
}

/**
 * @param {*} records 文件
 * @param {*} region 区域 0:中国，1：美国
 */
export const downloadFile = (records, region = 0, spaceId = 0) => {
  let filesCoopCount = 0;
  for (let i = 0; i < records.length; i++) {
    const record = records[i];
    if (!checkPermis('download', record.permission)) {
      message.error(intl.t('您无权限下载'));
      return;
    }
    if (record.type === 2) {
      filesCoopCount++;
    }
  }
  if (filesCoopCount === records.length) {
    message.info(intl.t('协作文档暂不支持下载，即将上线敬请期待'));
    return;
  }
  download(records, region, spaceId);
};

const download = (records, region, spaceId) => {
  const ids = records.map((r) => r.id);
  if (ids.length > 0) {
    let url = `${api.API_DOWNLOADS.replace(':spaceId', spaceId)}?region=${region}`;
    post(url, ids).then((res) => {
      dealDownloadRes(res);
    });
  }
};

export function jumpToUrl(url) {
  if (/^http.*/.test(url)) {
    window.location = url;
  } else {
    window.location = `/${url}`;
  }
}
