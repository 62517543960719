import { openNewWindow } from '@/utils/index';
import classBind from 'classnames/bind';
import { useSelector } from 'react-redux';
import { isDiDiTenant } from '@/utils/entryEnhance';
import helperFeedback from './FeedbackModal';
import { Tooltip } from 'antd';
import { intl } from 'di18n-react';
import styles from './style.module.less';

const cx = classBind.bind(styles);


function Feedback({ icon, placement }) {
  const { cooperLinkConf } = useSelector((state) => state.GlobalData);
  // eslint-disable-next-line camelcase, max-len
  const { feedBack_dc } = cooperLinkConf;

  const open = () => {
    window.__OmegaEvent(
      'ep_help_ck',
      '点击进入反馈群',
      {
        platform: 'knowledgeforge',
        tenant: isDiDiTenant() ? 'internal' : 'external',
      },
    );
    if (isDiDiTenant()) {
      openNewWindow(feedBack_dc)
    } else {
      helperFeedback();
    }
  }

  return (
    <div className={cx('feedback')}>
      <Tooltip
        placement={placement ?? 'bottomRight'}
        title={isDiDiTenant() ? intl.t('Cooper相关问题，请点击咨询') : intl.t('点击反馈Cooper问题')}
      >
        <div
          onClick={open}
          key={'feedback'}
          className={cx('content')}
        >
          {
            icon || <i className='dk-iconfont dk-icon-lianxikefu'/>
          }

        </div>
      </Tooltip>
    </div>
  );
}

export default Feedback;
