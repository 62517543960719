/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-23 12:18:28
 * @LastEditTime: 2023-06-20 16:57:27
 * @Description: 移除快速访问
 * @FilePath: /knowledgeforge/src/components/CooperOperation/RemoveQuick/index.js
 *
 */
import api from '@/utils/request/api/CooperApi';
import { del } from '@/utils/request/cooper';
import { message } from 'antd';
import { intl } from 'di18n-react';

async function removeQuick({ id, doneCallback }) {
  let url = `${api.QUICK_VISIT}?quickId=${id}`;

  await del(url);

  message.success({
    content: intl.t('已从“快速访问”移出'),
    key: 'quickAccess',
  });
  if (doneCallback) doneCallback();
}

export default removeQuick;
