import { intl } from 'di18n-react';
import { useState, useEffect, useRef, useContext } from 'react';
import { getUrlParams } from '@/utils';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import { RECENT_TABS, OWNER_TYPE } from '@/constants/recent';
import {
  getRecentVisitList,
  getRecentEditList,
} from '@/service/knowledge/recent';

export default function useRecentData(inMainPage) {
  const { knowledgeId } = useContext(LayoutContext);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [recentList, setRecentList] = useState([]);
  const activeTabRef = useRef(RECENT_TABS.VISIT);
  const deliverDkIdRef = useRef(false);
  const firstPaintRef = useRef(false);
  const pageNumRef = useRef(0);

  useEffect(() => {
    if (inMainPage && getUrlParams('key')) {
      activeTabRef.current = getUrlParams('key');
    }
    loadMore();
  }, []);

  const setOnlyCurrentDk = (value) => {
    deliverDkIdRef.current = value;
  };

  const tabInfo = [
    {
      name: intl.t('最近浏览'),
      key: RECENT_TABS.VISIT,
    },
    {
      name: intl.t('最近编辑'),
      key: RECENT_TABS.EDIT,
    },
  ];

  const loadMore = (refresh = false, ownerType = OWNER_TYPE.ALL) => {
    if (refresh) {
      refreshData();
    }
    setLoading(true);
    const loadFn = activeTabRef.current === RECENT_TABS.EDIT
      ? getRecentEditList
      : getRecentVisitList;
    return loadFn({
      pageNum: refresh ? 0 : pageNumRef.current,
      pageSize: Math.ceil((window.screen.height - 207) / 50),
      knowledgeId: deliverDkIdRef.current ? knowledgeId : undefined,
      ownerType,
    })
      .then((data) => {
        if (data) {
          const { currentPage, pageSize, totalCount } = data;
          const more = (currentPage + 1) * pageSize < totalCount;
          setHasMore(more);
          const newList = refresh
            ? data.items
            : recentList.concat(data.items || []);
          newList.forEach((element, index) => {
            element.key = index;
          });
          setRecentList(newList);
          pageNumRef.current += 1;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refreshData = () => {
    pageNumRef.current = 0;
    setRecentList([]);
  };

  const handleTabChange = (activeKey) => {
    if (activeTabRef.current === activeKey) return;
    activeTabRef.current = activeKey;
    loadMore(true);
  };

  return {
    tabInfo,
    loading,
    firstPaint: firstPaintRef.current,
    hasMore,
    loadMore,
    handleTabChange,
    recentList,
    activeTab: activeTabRef.current,
    setOnlyCurrentDk,
  };
}
