/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-20 15:28:32
 * @LastEditTime: 2024-03-26 15:34:08
 * @Description: 这里为了避免多次传入参数所以使用该方案
 * @FilePath: /knowledgeforge/src/utils/request/cooper.js
 *
 */
import { intl } from 'di18n-react';
import { Modal } from 'antd';
import axios from 'axios';
import NotificationStatus from '@/constants/notification';
import { inPhone, isIpad } from '@/utils';
import useNotification from '@/hooks/useNotification';
import { inDC } from '@/components/SearchContent/GlobalSearch/dcSdk';
// import { goModalLogin } from '@/utils/request/index';
// import { getSign } from '@didi/wsgsig';


const checkAuth = (response) => {
  const { errorCode, data } = response.data;
  if (errorCode === 401 && !window.__request_block) {
    // 无权限，禁止发送请求
    window.__request_block = true;
    if (data.redirect) {
      // sso重定向注意事项⚠️
      // 如果返回的redirect地址中有callback_index参数，则需要把域名去掉，否则会出现循环刷新问题
      const { href, origin } = window.location;
      let jumpTo = href.replace(origin, '');

      let temp = jumpTo.split('#');
      if (inPhone() || isIpad()) {
        let signal = temp[0].indexOf('?') !== -1 || temp[0].indexOf('%3F') !== -1
          ? '&'
          : '?';
        // temp[0] = `${href.replace(origin, '')}${signal}app_callback_index=1`;
        temp[0] = `${temp[0]}${signal}app_callback_index=1`;
        // let res = temp[1] ? `${temp[0]}#${temp[1]}` : temp[0];
        window.location.href = `${decodeURI(
          data.redirect,
        )}&jumpto=${encodeURIComponent(temp[0])}`;
      }
      // let res = temp[1] ? `${temp[0]}#${temp[1]}` : temp[0];
      // window.location.href = `${decodeURI(data.redirect)}&jumpto=${encodeURIComponent(res)}`;
      window.location.href = `${decodeURI(data.redirect)}&jumpto=${encodeURIComponent(temp[0])}`;
      // goModalLogin(data.redirect);
    }
  }
  // 用户属于多个租户，并且不属于滴滴租户：需要用户选择，返回租户列表
  if (errorCode === 403) {
    if (window.location.href.indexOf('tenant') === -1) {
      window.location.href = '/tenant'
    }
  }
};

const checkSecurity = (response) => {
  if (typeof response.success === 'boolean' && !response.success) {
    if (response.errorCode === 130000) {
      // 后端调用安全部接口识别可信设备（携带当前页面url，作为rederictUrl，和failUrl）
      // 当发现认证失败 返回130000状态码，同时返回安全认证页面url
      // 前端识别状态码&跳转安全认证页面url （用户输入D盾）
      // 认证成功页面重定向到rederictUrl（认证失败则代开failurl）
      window.location.href = response.data.safeCheckUrl;
      throw response;
    } else if (response.errorCode === 20030) {
      // 敏感操作设备认证
      // 当触发敏感操作（下载、导出）后端调用安全部接口认证设备
      // 当发现认证失败 返回20030状态码
      // 前端弹框提示
      Modal.warning({
        className: 'all-warn-tip',
        title: intl.t('提示'),
        content: response.errorMessage,
        okText: intl.t('好的'),
        zIndex: 10000,
      });
      throw response;
    }
    // message.error(response?.errorMessage);
  }
};

export const initDcToken = () => {
  return new Promise((resolve, reject) => {
    window.dcH5Sdk.app.getAuthToken({
      onSuccess(token) {
        resolve(token);
      },
      onFail(err) {
        reject(err);
      },
    });
  }).then((token) => {
    window._dc_token = token;
    return token;
  }).catch((err) => {
    console.log(err);
  });
};

const ajax = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'X-App-Id': 2,
    lcaInfo: window.lcaData,
  },
  withCredentials: true,
  keepSilent: false, // 公共错误提示
  validateStatus(status) {
    return (status >= 200 && status < 300) || status === 304; // 请求正常的状态码范围
  },
});

// 禁止发送请求
window.__request_block = false;

// 请求拦截器
ajax.interceptors.request.use(
  async (config) => {
    if (window.__request_block) {
      throw new Error('__request_block__');
    }
    let dcToken = window._dc_token;


    // let ngis = getSign({
    //   params: config.params,
    //   contentType: config.headers['Content-Type'],
    // });

    let headers = {
      ...config.headers,
      'X-App-Id': config.headers['X-App-Id'] ?? window.appId,
      // wsgsig: ngis, // 安全部验签策略
    }

    if (inDC) {
      if (!dcToken) {
        dcToken = await initDcToken();
      }

      if (dcToken) {
        headers = {
          ...headers,
          'X-User-Token': dcToken,
        }
      }
    }

    return {
      ...config,
      headers,
      url: window.originService
        ? config.url.replace('cooper_gateway', window.originService)
        : config.url,
    };
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 响应拦截器
ajax.interceptors.response.use(
  (response) => {
    const { config, status } = response;
    const { data, success, errorMessage, errorType, code } = response.data;
    if (status !== 200) {
      let errorMsg = errorMessage || errorType;
      if (
        !config.keepSilent
        && !window.__request_block
        && errorMsg
        && errorMsg.length > 0
      ) {
        useNotification()(NotificationStatus.ERROR, errorMsg, null, 2, null, false, config?.top);
      }
      throw response.data;
    }
    if (success || code === 200) {
      return data;
    }
    // TODO: 优化
    checkSecurity(response.data);
    throw response.data;
  },
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }
    // 断网提示
    // if (!window.navigator.onLine) {
    //   useNotification()(
    //     NotificationStatus.ERROR,
    //     intl.t('当前网络已断开，请联网后重试'),
    //     null,
    //     2,
    //     'offline',
    //   );
    //   return Promise.reject(error);
    // }
    const { response, config } = error;
    const { errorMessage, errorType, errorCode } = response?.data;
    checkAuth(response);
    checkSecurity(response);
    let errorMsg = errorMessage || errorType;
    if (
      !config.keepSilent
      && !window.__request_block
      && errorCode !== 401
      && errorCode !== 403
      && errorMsg
      && errorMsg.length > 0
      && errorCode !== 1601
    ) {
      useNotification()(NotificationStatus.ERROR, errorMsg);
    }
    // return Promise.reject(error);
    throw response.data;
  },
);

// 上传需要取消请求
export function putWithCancel(url, body = {}, config = {}) {
  const source = axios.CancelToken.source();

  return [
    // 此处需针对s3服务响应做处理，该响应没有success字段,故不走全局拦截器
    axios.create().put(url, body, {
      cancelToken: source.token,
      timeout: 300000,
      withCredentials: false,
      ...config,
    }),
    source.cancel,
  ];
}

const { get, post, patch, put } = ajax;
const del = ajax.delete;
export { get, post, patch, put, del };

export default ajax;
