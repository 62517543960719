import OperateModal from '@didi/select-position-operate';
import { useSelector } from 'react-redux';
import { acceptFileSuffixes } from '@/constants/uploadWiki';


function OperateFileModal(props) {
  const { IsExternalTenant } = useSelector((state) => state.GlobalData);
  // 只判断非批量/非文件夹的情况
  const optionEnhance = (resourceTypeCurr, file) => {
    const { name: fileName } = file ?? {};
    const isExceptDoc = resourceTypeCurr !== 'FILE' && ['DI_DOC', 'SHIMO2_PPT', 'COOPER_SLICE', 'COOPER_ANONYMOUS_DOC'].includes(resourceTypeCurr);
    const isExceptFile = resourceTypeCurr === 'FILE' && !acceptFileSuffixes.includes(fileName?.substring(fileName.lastIndexOf('.'))?.toLowerCase() ?? 'UNKNOWN');
    return isExceptDoc || isExceptFile;
  }
  const resourceType = props?.files[0]?.space_resource_type || props?.files[0]?.resource_type || props?.files[0]?.objectType || 'NONE'; // 快速访问objectType

  return <OperateModal
    {...props}
    isFromPerson={props.isFromPerson}
    onClose={props.onClose}
    isShowPerson={!IsExternalTenant}
    isNeedTypeTips={resourceType === 'DIR' || props.isBatch}
    acceptFileSuffixes={acceptFileSuffixes}
    isDisabledFile={props.isBatch ? false : optionEnhance(resourceType, props?.files[0]) }
  />
}

export default OperateFileModal;


