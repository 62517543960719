/* eslint-disable max-classes-per-file */

import { intl } from 'di18n-react';
import React from 'react';
import { Radio, Checkbox, Select, Button, Input, message, Tooltip } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { post } from '@/utils/request/cooper';
import api from '@/utils/request/api/CooperApi';
import './LinkShare.less';
import store from '@/model';
import { IN_OUT } from '@/constants/space';

const { Option } = Select;

function createValidateCode() {
  const codes = []; // 0-9

  for (let i = 48; i <= 57; i++) codes.push(String.fromCharCode(i)); // A-Z

  for (let i = 65; i <= 90; i++) codes.push(String.fromCharCode(i)); // a-z

  for (let i = 97; i <= 122; i++) codes.push(String.fromCharCode(i));

  return [0, 1, 2, 3, 4, 5].map(() => codes[Math.floor(Math.random() * 62)]).join('');
}

class ShareSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'private',
      download: false,
    };

    const { IsExternalTenant, relationTypeTags } = props;
    this.expire = (IsExternalTenant || (relationTypeTags || []).includes(IN_OUT)) ? '3' : '2';
  }

  createShareLink = () => {
    const { type, download } = this.state;
    const pswd = type === 'private' ? createValidateCode() : '';
    post(api.CREATE_SHARE, {
      share_type: 2,
      resource_id: this.props.fileId,
      expiration: this.expire,
      password: pswd,
      read_only: !download,
    }).then((data) => {
      const { protocol, host } = window.location;
      const link = `${protocol}//${host}/shares/${data.link_id}`;
      this.props.nextStep(link, pswd);
    });
  };

  onChange = (e) => {
    this.setState({
      type: e.target.value,
    })
  }

  render() {
    const { onClose, relationTypeTags, IsExternalTenant } = this.props;
    const { type, download } = this.state;

    return (
      <div className='share-setting'>
        <div className='mode'>
          <div className='label-text'>{intl.t('分享类型')}</div>
          <Radio.Group
            onChange={this.onChange}
            value={type}>
            <Radio value={'private'}>
              {intl.t('私密')} <span className='type-des'>{intl.t('需要密码验证才可以查看分享内容')}</span>
            </Radio>
            {
              (IsExternalTenant || (relationTypeTags || []).includes(IN_OUT)) ? (
                <Tooltip
                  placement='topLeft'
                  title={intl.t('外部空间内的文件不支持设为公开')}
                >
                  <Radio
                    value={'public'}
                    disabled={true}>
                    {intl.t('公开')} <span className='type-des'>{intl.t('任何人都可通过链接查看分享内容')}</span>
                  </Radio>
                </Tooltip>

              ) : (
                <Radio value={'public'}>
                  {intl.t('公开')} <span className='type-des'>{intl.t('任何人都可通过链接查看分享内容')}</span>
                </Radio>
              )
            }
          </Radio.Group>
        </div>

        <div className='expire'>
          <div className='label-text'>{intl.t('有效期')}</div>
          <Select
            defaultValue={(IsExternalTenant || (relationTypeTags || []).includes(IN_OUT)) ? '3' : '2'}
            size='large'
            style={{
              width: 140,
            }}
            getPopupContainer={(triggerNode) => triggerNode.parentNode.parentNode.parentNode.parentNode}
            onChange={(v) => (this.expire = Number(v))} // eslint-disable-line no-return-assign
          >
            {/* 非外部租户 或者 非外部空间 */}
            {!(IsExternalTenant || (relationTypeTags || []).includes(IN_OUT)) && <Option value='1'>{intl.t('永久有效')}</Option>}
            <Option value='2'>1{intl.t('个月')}</Option>
            <Option value='3'>1{intl.t('周')}</Option>
            <Option value='4'>1{intl.t('天')}</Option>
          </Select>
        </div>

        <div className='permis'>
          <div className='label-text'>{intl.t('访问权限')}</div>
          <div>
            <Checkbox
              value={this.state.download}
              onChange={() => {
                this.setState({
                  download: !download,
                });
              }
              }
            />
            {intl.t('可下载')}
          </div>
        </div>

        <div className='foot'>
          <div className='button-container'>
            <Button
              className='two-word'
              onClick={onClose}>
              <i />
              {intl.t(' 取消')}
            </Button>
            <Button
              type='primary'
              onClick={this.createShareLink}>
              {intl.t('创建链接')}
            </Button>
          </div>
          <div className='warn'>
            {intl.t('您需对分享内容负责，请确认分享内容及访问权限')}
          </div>
        </div>
      </div>
    );
  }
}

class ShareResult extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isInit: true,
    }
  }

  componentDidMount() {
    document.querySelector('.button-copy').click()
    this.setState({
      isInit: false,
    })
  }

  render() {
    const { link, pswd, onClose, fileName } = this.props;
    let copy = `${intl.t('名称')}：${fileName}\n${intl.t('链接')}：${link}`;
    copy += pswd ? `\n${intl.t('密码')}：${pswd}` : '';
    return (
      <div className='share-result'>
        <div className='link-pswd'>
          {pswd ? `${intl.t('链接及密码')}：` : `${intl.t('链接')}：`}
          <div className={`input-container${pswd ? '' : ' no-pswd'}`}>
            <Input.Group>
              <Input
                value={link}
                readOnly />
              {pswd ? (
                <Input
                  value={`${intl.t('密码')}: ${pswd}`}
                  readOnly />
              ) : null}
            </Input.Group>
          </div>
        </div>

        <div className='foot'>
          <div className='button-container'>
            <Button
              className='two-word'
              onClick={onClose}>
              <i />
              {intl.t(' 取消')}
            </Button>
            <CopyToClipboard
              text={copy}
              onCopy={(e) => {
                if (!this.state.isInit) {
                  message.success(intl.t('复制成功'));
                  window.__OmegaEvent('ep_fileshare_linkshare_copylink_ck');
                }
              }}
            >
              <Button
                type='primary'
                className='button-copy'>
                {pswd ? intl.t('复制链接和密码') : intl.t('复制链接')}
              </Button>
            </CopyToClipboard>
          </div>

          <div className='warn copyLinkTip'>
            {intl.t('您需对分享内容负责，请确认分享内容及访问权限')}
          </div>
        </div>
      </div>
    );
  }
}

// 全员分享
class LinkShare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      link: '',
      pwsd: '',
    };
  }

  render() {
    const { fileId, onClose, show = true, fileName, relationTypeTags } = this.props;
    const { IsExternalTenant } = store.getState().GlobalData;

    const { step, link, pswd } = this.state;
    return (
      <div className={`link-share ${show ? '' : ' hidden'}`}>
        {step === 0 ? (
          <ShareSetting
            IsExternalTenant={IsExternalTenant}
            relationTypeTags={relationTypeTags}
            fileId={fileId}
            onClose={onClose}
            nextStep={(l, p) => this.setState({
              step: 1,
              link: l,
              pswd: p,
            })
            }
          />
        ) : (
          <ShareResult
            onClose={onClose}
            link={link}
            pswd={pswd}
            fileName={fileName}
          />
        )}
      </div>
    );
  }
}

export default LinkShare;
