/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-24 17:35:08
 * @LastEditTime: 2024-04-11 17:15:53
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/service/cooper/teamSpace.js
 *
 */
// import { TEAMS_RECENT, TEAM_LIST, SPACE_QUOTA_APPLY_QUERY } from '../../api';
import api from '@/utils/request/api/CooperApi';
import DkApi from '@/utils/request/api/DkApi';
import { get, post } from '@/utils/request/cooper';
import { get as dkGet } from '@/utils/request';
import { isDC } from '@/utils/index';
import { message } from 'antd';

export const COOPER_TEAM_LIST_INIT_RECENT = 'COOPER_TEAM_LIST_INIT_RECENT';
export const COOPER_TEAM_LIST_INIT_MINE = 'COOPER_TEAM_LIST_INIT_MINE';
export const COOPER_TEAM_LIST_INIT_NOT_MINE = 'COOPER_TEAM_LIST_INIT_NOT_MINE';

export const formatTeamsQuota = async (teams) => {
  return post(api.SPACE_QUOTA_APPLY_QUERY, teams.map((item) => item.id)).then((data) => {
    const formatTeams = teams.map((teamsItem) => {
      for (const dataItem of data) {
        if (String(dataItem.id) === String(teamsItem.id)) {
          teamsItem = { ...teamsItem, ...dataItem, hasQuota: true };
        }
      }
      return teamsItem;
    });
    return formatTeams
  });
};

export const getTeamListInitRecent = async () => {
  let recent = await get(api.TEAMS_RECENT, {
    params: {
      maxSize: 8,
    },
  });
  recent = recent.map((item) => ({ ...item, hasQuota: false }));
  // if (recent.length) {
  //   recent = formatTeamsQuota(recent);
  // }
  return recent;
};

export const getTeamListInOut = async () => {
  let data = await get(api.SPACE_LIST_OUT_LIST);
  return data;
};

export const getTeamListInitMine = async ({ownerType, sortField}) => {
  let data = await get(api.TEAM_LIST_OWNER, {
    params: {
      ownType: ownerType ?? '0', // 0 全部   1 我创建的  2转交给我的
      sortField: sortField ?? '1', // 0 创建时间 1 访问时间
    },
  });
  data = data.map((item) => ({ ...item, hasQuota: true }));
  // if (data.length) {
  //   data = await formatTeamsQuota(data);
  // }
  return data;
};

export const getTeamListInitNotMine = async () => {
  let data = await get(api.TEAM_LIST, {
    params: {
      owner: false,
    },
  });
  data = data.map((item) => ({ ...item, hasQuota: true }));
  // if (data.length) {
  //   data = await formatTeamsQuota(data);
  // }
  return data;
};

export const spaceQuotaApply = ({ teamId = 0, surplusQuota, reason }) => {
  const data = post(api.SPACE_QUOTA_APPLY.replace(':teamId', teamId), { surplusQuota, reason });
  return data;
};

export const spaceSafeSummary = ({ teamId }) => {
  const data = get(api.SPACE_SAFE_SUM.replace(':spaceId', teamId));
  return data;
};

export const setVisitedSuit = async (teamId, suitType) => {
  return await post(api.SET_VISITED_SUITE.replace(':teamId', teamId).replace(':suitType', suitType)); // eslint-disable-line no-return-await
};

export const getRelateTeamDk = async (teamId) => {
  try {
    const res = await dkGet(DkApi.TEAM_DK.replace(':teamId', teamId), {
      keepSilent: true,
    });
    return res
  } catch (error) {
    if (error.errorCode !== 501082) {
      message.error(error.message)
    }
    return [];
  }
}

export const reqSuite = (teamId) => {
  let host = '';
  if (window.location.hostname === ('cooper.didichuxing.com') || window.location.hostname === 'cooper-x.didichuxing.com') {
    host = 'https://team.didichuxing.com';
  } else if (window.location.hostname.includes('qa.didichuxing.com')) {
    host = 'https://team-qa.intra.xiaojukeji.com/zhuronghao';
  } else host = 'https://team-test.didichuxing.com';
  return post(`${host}${api.GET_SUITLIST}`, {
    clientTag: isDC ? 'PC' : 'WEB',
    suiteSourceId: teamId,
    suiteSourceType: 'Cooper',
  }, {
    headers: {
      'X-App-Id': 6,
    },
  })
};

// 查询团队空间首页公告
export const getSpaceNotice = async (spaceId) => {
  const data = await get(api.SPACE_NOTICE.replace(':spaceId', spaceId));
  return data;
}

// 设置团队空间首页公告
export const saveSpaceNotice = async (spaceId, content) => {
  const data = await post(api.SPACE_NOTICE.replace(':spaceId', spaceId), { content });
  return data;
}

// 编辑团队空间首页公告加锁
export const addLock = async (spaceId) => {
  const data = await post(api.SPACE_NOTICE_LOCK.replace(':spaceId', spaceId));
  return data;
}

// 编辑团队空间首页公告解锁
export const removeLock = async (spaceId) => {
  const data = await post(api.SPACE_NOTICE_UNLOCK.replace(':spaceId', spaceId));
  return data;
}

export const removeTeamListInOut = async (applyId) => {
  const data = await post(api.SPACE_TEAM_REMOVE_IN_OUT.replace(':applyId', applyId));
  return data;
}
