import enUS from '@/language/en-US.json';
import zhCN from '@/language/zh-CN.json';
import { getLocalLanguageFromCookie } from '@/utils/cooperutils';

const textNeedIntl = [
  '文件回收站',
  '空间回收站',
  '分享给我',
  '我分享的',
  '首页',
  '个人',
  '团队',
  '知识库',
  '分享',
  '收藏1', // Fav. 左侧小图标
  '回收站',
  '更多',
  '全新升级，快来体验',
  '高级搜索',
  '上传',
  '新建',
  '快速访问',
  '最近访问',
  '最近编辑',
  '全部文件',
  '当前空间',
  '全部团队空间',
  '新建团队空间',
  '我的收藏',
  '收藏',
  '常见问题'
]

function replaceIntl(s) {
  const lang = getLocalLanguageFromCookie();
  let dict = lang === 'en-US' ? enUS : zhCN;
  let ret = s;
  for (const key of textNeedIntl) {
    // const reg = new RegExp(`(?<![\\p{L}\\w])${key}(?![\\p{L}\\w])`, 'gu')
    // if (dict[key]) {
    //   ret = ret.replace(reg, dict[key])
    // }
    const reg = new RegExp(`(${key})`, 'g');
    if (ret.includes(key)) {
      if (dict[key]) {
        ret = ret.replace(reg, dict[key]);
      }
    }
  }
  return ret;
}

export default replaceIntl
