/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-15 16:41:43
 * @LastEditTime: 2023-10-24 15:05:12
 * @Description: 表格展示
 * @FilePath: /knowledgeforge/src/components/RecentActivity/file-table/index.js
 *
 */
import classBind from 'classnames/bind';
import { useSelector } from 'react-redux';
import { getLocale, intl } from 'di18n-react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import Loading from '../../SpinRender';
import { FILETYPE } from '@/components/CooperFilesList/FoldTree/utils';
import handleFileClick from '@/components/CooperOperation/HandleFileClick';
import NoMore from '@/components/NoMore';
import OperateMenu from '@/components/OperateMenu';
import { updateQuicks } from '@/components/QuickAccess/index';
import SpinRender from '@/components/SpinRender';
import FileEllipsis from '@/components/common/FileEllipsis';
import ImageEnlarger from '@/components/common/ImageEnlarger';
import { FileType } from '@/constants/cooper';
import { FlowChart, RECENT_LIST } from '@/constants/cooperConstants';
import { setImgUrl } from '@/utils/cooperutils';
import { formatRecentTime, isDC } from '@/utils/index';
import store from '@/model';
import { Divider, Popover, Tooltip } from 'antd';
import { debounce } from 'lodash-es';
import CooperEmptyTip from '../../CooperEmptyTip/index';
import goToPath from '../goToPath';
import styles from './style.module.less';
import { entryEnhance } from '@/utils/entryEnhance';

// 初始化数据最近访问
const initRecentFilter = ['coo_doc', 'coo_sheet', 'coo_ppt', 'coo_file'];
const initTenantRecentFilter = ['all'];
// 初始化数据最近编辑
const initEditeFilter = ['coo_doc', 'coo_sheet', 'coo_ppt'];
const initTenantEditeFilter = ['all'];

const recentFilterAll = ['coo_doc', 'coo_sheet', 'coo_ppt', 'coo_file', 'wiki'];
const recentFilterAllTenant = ['coo_doc', 'coo_sheet', 'coo_file'];

const editeFilterAll = ['coo_doc', 'coo_sheet', 'coo_ppt', 'wiki'];
const editeFilterAllTenant = ['coo_doc', 'coo_sheet'];

// const CheckboxGroup = Checkbox.Group

const cx = classBind.bind(styles);

// coo_doc,coo_sheet,coo_ppt,coo_file,dk_page
const FilterResourceTypeRecentValue = [
  {
    text: () => intl.t('全部类型'),
    value: 'all',
  },
  {
    text: () => intl.t('文档'),
    value: 'coo_doc',
  },
  {
    text: () => intl.t('表格'),
    value: 'coo_sheet',
  },
  {
    text: () => intl.t('幻灯片'),
    value: 'coo_ppt',
  },
  {
    text: () => intl.t('文件'),
    value: 'coo_file',
  },
  {
    text: () => intl.t('Wiki页面'),
    value: 'wiki',
  },
];

const FilterResourceTypeRecentValueTenant = [
  {
    text: () => intl.t('全部类型'),
    value: 'all',
  },
  {
    text: () => intl.t('文档'),
    value: 'coo_doc',
  },
  {
    text: () => intl.t('表格'),
    value: 'coo_sheet',
  },
  {
    text: () => intl.t('文件'),
    value: 'coo_file',
  },
];

// coo_doc,coo_sheet,coo_ppt,dk_page
const FilterResourceTypeEditeValue = [
  {
    text: () => intl.t('全部类型'),
    value: 'all',
  },
  {
    text: () => intl.t('文档'),
    value: 'coo_doc',
  },
  {
    text: () => intl.t('表格'),
    value: 'coo_sheet',
  },
  {
    text: () => intl.t('幻灯片'),
    value: 'coo_ppt',
  },
  {
    text: () => intl.t('Wiki页面'),
    value: 'wiki',
  },
];

const FilterOwnerValue = [
  {
    text: () => intl.t('不限归属'),
    value: 'all',
  },
  {
    text: () => intl.t('归我所有'),
    value: 'me',
  },
];

const isRegularFile = (type) => {
  // 0：文件，1： 文件夹
  return type === FileType.FILE || type === FileType.DIR;
};

// 列表不返回已经删除的文件,实际可以不进行判断
const handlePathClick = (f, navigate) => {
  const params = {
    resourceTypeStr: f.resourceTypeStr,
    teamId: f.teamId,
    shareLink: f.shareLink,
    shareId: f.shareId,
    id: f.parentId,
    deleted: f.deleted,
  };
  goToPath(params, '', navigate);
};

const isDk = (type) => {
  return type === FileType.DK_FILE || type === FileType.DK_PAGE;
};

function operateConfig(type) {
  if (isDk(type)) {
    return {
      copyLink: true,
      duplicateOperate: false,
      multiSelectOperate: false,
      starOperate: false,
      moveOperate: false,
      copyOperate: false,
      deleteOperate: false,
      downloadOperate: false,
      renameOperate: false,
      shareOperate: false,
    };
  }
  if (type === FileType.WIKI_PAGE) {
    return {
      copyLink: true,
      duplicateOperate: false,
      multiSelectOperate: false,
      starOperate: false,
      moveOperate: false,
      copyOperate: false,
      deleteOperate: false,
      downloadOperate: false,
      renameOperate: false,
      shareOperate: false,
      quickAccessOperate: false,
    };
  }
  return {
    downloadOperate: isRegularFile(type),
    duplicateOperate: false,
    starOperate: false,
    multiSelectOperate: false,
    // deleteOperate: type !== FileType.FLOWCHART,
    deleteOperate: false,
  };
}

function FileTables(props) {
  const {
    rowKey,
    filteredInfo,
    getWholePath,
    updateCurrentList,
    doFilters,
    dataSource,
    loading,
    loadEnd,
  } = props;
  const nameRef = useRef(null);
  const [typeVisible, setTypeVisible] = useState(false);
  const [ownerVisible, setOwnerVisible] = useState(false);
  const { IsExternalTenant } = useSelector((state) => state.GlobalData);
  const [filterMsg, setFilterMsg] = useState({ ...filteredInfo });
  const [, setRefresh] = useState(0);
  const navigate = useNavigate();
  const isRecentTab = rowKey == 1;
  let checkedValue = filterMsg.type.split(',') || [];
  let len = dataSource.length || 0;
  const curLocale = getLocale();

  // 工具函数，生成所需的常量
  const _getInitRecentFilter = () => {
    if (IsExternalTenant) {
      return initTenantRecentFilter
    }
    return initRecentFilter
  }
  const _getInitEditeFilter = () => {
    if (IsExternalTenant) {
      return initTenantEditeFilter
    }
    return initEditeFilter
  }
  const _getRecentFilterAll = () => {
    if (IsExternalTenant) {
      return recentFilterAllTenant
    }
    return recentFilterAll
  }
  const _getEditeFilterAll = () => {
    if (IsExternalTenant) {
      return editeFilterAllTenant
    }
    return editeFilterAll
  }
  // 重复赋值，禁用
  useEffect(() => {
    setFilterMsg(filteredInfo);
  }, [filteredInfo]);

  function updateFilteredMsg(key, value) {
    let temp = filterMsg;
    setFilterMsg(temp);
    temp[key] = value.toString();
    return temp;
  }

  const handleClickFilter = debounce(
    (value, type) => {
      if (!checkedValue.includes(value)) {
        if (value === 'all') {
          checkedValue = ['all'];
        } else {
          checkedValue.push(value);
          if (checkedValue.includes('all')) {
            checkedValue.splice(checkedValue.indexOf('all'), 1);
          }
          if (checkArrIsSame(isRecentTab ? _getRecentFilterAll() : _getEditeFilterAll())) {
            checkedValue = ['all'];
          }
        }
        handleFilters(type);
      } else if (checkedValue.length === 1) {
        //
      } else {
        checkedValue.splice(checkedValue.indexOf(value), 1);
        handleFilters(type);
      }
    },
    500,
    {
      leading: true,
      trailing: false,
    },
  );

  const checkArrIsSame = (initFileArr) => {
    let filterArr = initFileArr.filter((item) => checkedValue.includes(item));
    return filterArr.length === initFileArr.length;
  };

  const handleFilters = (type) => {
    let obj = updateFilteredMsg(type, checkedValue.join(','));
    doFilters(obj, type);
  };

  const resentFilter = debounce(
    (type) => {
      let currValue = isRecentTab ? _getInitRecentFilter() : _getInitEditeFilter();
      if (checkArrIsSame(currValue)) return;
      checkedValue = currValue;
      handleFilters(type);
    },
    500,
    {
      leading: true,
      trailing: false,
    },
  );

  // 处理像option这样的数据
  const optionEnhance = (options) => {
    // 判断是否为多租户，否直接返回入参。是经过处理
    if (IsExternalTenant) {
      const exceptList = ['coo_ppt', 'wiki'];
      return options.filter((v) => !exceptList.includes(v.value));
    }
    return options;
  };

  const getFilterInfoName = () => {
    if (filterMsg.type.split(',').length > 1) {
      return `${intl.t('类型')}(${filterMsg.type.split(',').length})`;
    }
    let valueMap = isRecentTab
      ? optionEnhance(FilterResourceTypeRecentValue)
      : optionEnhance(FilterResourceTypeEditeValue);
    return valueMap
      .filter((item) => item.value == filterMsg.type.split(','))[0]
      ?.text();
  };
  const filterToolitip = () => {
    const { IsExternalTenant } = store.getState().GlobalData
    return (
      <div className={cx('filter-tip-hover-content')}>
        <p className={cx('filter-tip-hover-content-title')}>
          {intl.t('类型说明')}
        </p>
        {IsExternalTenant ? (
          <>
            <p>{intl.t('文档：Cooper协作文档')}</p>
            <p>{intl.t('表格：Cooper协作表格')}</p>
            <p>{intl.t('文件：本地上传的Doc、Excel、PDF等文件')}</p>
          </>
        ) : <>
          <p>{intl.t('文档：Cooper协作文档、匿名文档、知识库文档')}</p>
          <p>{intl.t('表格：Cooper协作表格')}</p>
          <p>{intl.t('幻灯片：Cooper协作幻灯片')}</p>
          <p>{intl.t('文件：Cooper、知识库上传的本地文件')}</p>
          <p>{intl.t('Wiki页面：Wiki页面')}</p>
        </>}
      </div>
    );
  };
  const renderFilterModal = (type) => {
    let valueMap = [];
    let currValue = null;

    if (type === 'ownerType') {
      valueMap = FilterOwnerValue;
      currValue = filterMsg.ownerType;
    }
    if (type === 'type') {
      valueMap = isRecentTab
        ? optionEnhance(FilterResourceTypeRecentValue)
        : optionEnhance(FilterResourceTypeEditeValue);
    }

    if (type === 'ownerType') {
      return (
        <ul className={cx('custom-filter-dropdown')}>
          {valueMap.map((item, index) => {
            return (
              <li
                key={item.value}
                className={cx({
                  'choose-li': currValue === item.value,
                })}
                onClick={() => {
                  setOwnerVisible(false);
                  let filteredInfoNew = updateFilteredMsg(type, item.value);
                  doFilters(filteredInfoNew, type);
                }}
              >
                {item.text()}
                <i
                  className={cx('dk-iconfont', 'dk-icon-gou', 'icon-selected')}
                />
              </li>
            );
          })}
        </ul>
      );
    }
    return (
      <ul
        className={cx('custom-filter-dropdown')}
        key={rowKey}>
        {/* <li>
            <Checkbox onChange={(e) => onCheckAllChange(e, type, rowKey)} checked={checkAll}>全部类型</Checkbox>
          </li> */}
        {/* {
          valueMap.map((item, index) => {
            return (
              <li key={index}>
                <Checkbox
                  value={item.value}
                  onChange={(e) => onChenkBoxChange(e, type, rowKey)}
                  checked={checkedValue.includes(item.value)}>{item.text()}</Checkbox>
              </li>
            )
          })
        } */}
        {/* <div className={cx({'resent-btn': true, 'disable': checkAll })} onClick={() => resentChecked(type, rowKey)}>
            <span>重置</span>
          </div> */}
        <li
          className={cx({
            'filter-resent': true,
            'filter-action': !checkArrIsSame(
              isRecentTab ? _getInitRecentFilter() : _getInitEditeFilter(),
            ),
          })}
          onClick={() => resentFilter(type)}
        >
          {intl.t('重置')}{' '}
        </li>
        <Divider className={cx('filter-divider')} />
        <li className={cx('filter-tip')}>
          {intl.t('类型可多选')}
          <Tooltip
            title={filterToolitip()}
            overlayClassName="filter-tooltip">
            <i className={cx('dk-iconfont', 'dk-icon-a-tishi2', 'icon-tip')} />
          </Tooltip>
        </li>
        {valueMap.map((item) => {
          return (
            <li
              key={item.value}
              className={cx({
                'choose-li': checkedValue.includes(item.value),
              })}
              onClick={() => handleClickFilter(item.value, type)}
            >
              {item.text()}
              <i
                className={cx('dk-iconfont', 'dk-icon-gou', 'icon-selected')}
              />
            </li>
          );
        })}
      </ul>
    );
  };

  const handleOpenType = (newOpen) => {
    setTypeVisible(newOpen);
  };
  const handleOpenOwner = (newOpen) => {
    setOwnerVisible(newOpen);
  };

  const getWholFileAdress = (item) => {
    const { spaceType, fileDir, resourceTypeStr, shareType } = item;
    if (fileDir === '--') {
      return fileDir;
    }
    if (spaceType === 'TEAM_SPACE' && !shareType) {
      return `${intl.t('团队空间')}/${fileDir}`;
    }
    if (isDk(resourceTypeStr)) {
      return `${intl.t('知识库')}/${fileDir}`;
    }
    if (
      spaceType === 'PERSONAL_SPACE'
      && fileDir !== intl.t('个人空间')
      && !shareType
    ) {
      return `${intl.t('个人空间')}/${fileDir}`;
    }
    if (spaceType === 'WIKI_SPACE') {
      return `${intl.t('Wiki')}/${fileDir}`;
    }
    return fileDir;
  };
  return (
    <div className={cx('cooper-list-content')}>
      <Fragment>
        <div className={cx('folder-tree')}>
          <div className={cx('tb-header', { 'dc-tb-header': isDC() })}>
            <div className={cx('tb-header-div')}>
              <span
                className={cx('file-name')}
                ref={nameRef}>
                <Popover
                  content={renderFilterModal('type')}
                  placement="bottomLeft"
                  trigger="click"
                  visible={typeVisible}
                  onVisibleChange={handleOpenType}
                  zIndex={99}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode.parentNode.parentNode
                  }
                >
                  <div
                    className={cx({
                      'file-handle': true,
                      'file-checked': filterMsg.type !== 'all' || typeVisible,
                    })}
                  >
                    <Fragment>
                      {getFilterInfoName()}
                      <i
                        className={cx({
                          'dk-iconfont': true,
                          'dk-icon-shaixuan2': filterMsg.type !== 'all',
                          'icon-shaixuan2': true,
                          'dk-icon-zhankai': filterMsg.type == 'all',
                        })}
                      />
                    </Fragment>
                  </div>
                </Popover>
              </span>
              {/* <span className={cx('file-resizer')}>
              <Resizer onBegin={resizeBegin} onResize={resizing} onEnd={onEnd} />
            </span> */}
              <span className={cx('file-owner')}>
                <Popover
                  content={renderFilterModal('ownerType')}
                  placement="bottomLeft"
                  trigger="click"
                  visible={ownerVisible}
                  onVisibleChange={handleOpenOwner}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode.parentNode.parentNode
                  }
                >
                  <div
                    className={cx({
                      'file-handle': true,
                      'file-handle-name': true,
                      'file-checked':
                        filterMsg.ownerType !== 'all' || ownerVisible,
                    })}
                  >
                    {filterMsg.ownerType === 'all' ? (
                      <Fragment>
                        {intl.t('不限归属')}
                        <i
                          className={cx(
                            'dk-iconfont',
                            'dk-icon-zhankai',
                            'icon-zhankai1',
                          )}
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        {intl.t('归我所有')}
                        <i
                          className={cx(
                            'dk-iconfont',
                            'dk-icon-shaixuan2',
                            'icon-shaixuan2',
                          )}
                        />
                      </Fragment>
                    )}
                  </div>
                </Popover>
              </span>
              <span className={cx('file-address')}>{intl.t('文件位置')}</span>
              <span className={cx('file-time')}>
                {isRecentTab ? intl.t('打开时间') : intl.t('编辑时间')}
              </span>
              {isRecentTab && (
                <span className={cx('file-operate')}>{intl.t('操作')}</span>
              )}
            </div>
          </div>
          <div className={cx('tb-body', { 'dc-tb-body': isDC() })}>
            {dataSource
              && dataSource.map((item, index) => {
                return (
                  <li
                    className={cx('tb-body-row')}
                    key={index}>
                    <span
                      className={cx('file-name')}
                      onClick={() => {
                        handleFileClick({
                          fileTypeStr: item.resourceTypeStr,
                          resourceId: item.resourceId,
                          resourceName: item.resourceName,
                          knowledgeId: item.teamId,
                          dkShareType: item.shareType,
                          pageId: item.resourceId,
                          shareId: item.shareId,
                          shareLink: item.shareLink,
                          markedQuickVisit: item.markQuickVisit,
                          teamId: item.teamId,
                          quickId: item.quickId,
                          parentId: item.parentId,
                          spaceId: item.spaceId,
                          relationTypeTags: item.relationTypeTags,
                        });

                        if (isRecentTab) {
                          window.__OmegaEvent('ep_home_recent_visit_ck', '', {
                            resourceid: item.resourceId,
                            resource_id: item.resourceId,
                            position: index,
                            platform: 'new',
                            type: FILETYPE[item.resourceType],
                          });
                        } else {
                          // TODO:
                          window.__OmegaEvent('ep_home_edited_visit_ck', '', {
                            resourceid: item.resourceId,
                            resource_id: item.resourceId,
                            position: index,
                            platform: 'new',
                            type: FILETYPE[item.resourceType],
                          });
                        }
                      }}
                    >
                      <ImageEnlarger
                        src={setImgUrl(item)}
                        isTiny={!!item.tiny}
                        mimeType={item.mime_type || item.mimeType}
                        resourceType={item.resourceTypeStr}
                      />
                      <FileEllipsis
                        value={item.resourceName}
                        record={item}
                        hasVisitorTag={
                          isDk(item.resourceTypeStr) && item.shareLink !== ''
                        }
                      />
                    </span>
                    {/* <div className={cx('file-resizer')} style={{ height: 50 }}>
                     <div className={cx('resizer-line')} style={{ display: isDragging ? 'block' : 'none' }} />
                    </div> */}
                    <span className={cx('file-owner')}>
                      <div
                        className={cx(
                          'file-content-box',
                          'file-owner-content-box',
                        )}
                      >
                        {curLocale === 'zh-CN'
                          ? item?.userInfo?.user_cn || '--'
                          : item?.userInfo?.user || '--'}
                      </div>
                    </span>
                    <span className={cx('file-address')}>
                      <Tooltip
                        title={item.globalDir || '--'}
                        placement="topLeft"
                      >
                        <div
                          className={cx('file-content-box')}
                          onMouseOver={() => {
                            if (!item.globalDir) {
                              getWholePath(item, index);
                              setRefresh(Date.now());
                            }
                          }}
                          onClick={() => {
                            handlePathClick(item, navigate);
                          }}
                        >
                          {getWholFileAdress(item)}
                        </div>
                      </Tooltip>
                    </span>
                    <span className={cx('file-time')}>
                      <div className={cx('file-content-box')}>
                        {isRecentTab
                          ? formatRecentTime(item.visitOn)
                          : formatRecentTime(item.operationOn)}
                      </div>
                    </span>
                    {isRecentTab && (
                      <span className={cx('file-operate')}>
                        <OperateMenu
                          file={item}
                          doneCallback={[updateCurrentList, updateQuicks]}
                          config={operateConfig(item.resourceTypeStr)}
                          originFileType={RECENT_LIST}
                          isFlowChart={
                            item.resourceType === FlowChart
                            && item.mimeType === 9
                          }
                          key={Date.now()}
                          isTeam={item.spaceType === 'TEAM_SPACE'}
                        />
                      </span>
                    )}
                  </li>
                );
              })}
          </div>
        </div>
        {!loading && len === 0 ? (
          <CooperEmptyTip title={intl.t('暂无数据')} />
        ) : (
          <div className={cx('cooper-recent-table-loading')}>
            {loadEnd && !loading && <NoMore />}
            <SpinRender loading={loading} />
          </div>
        )}
      </Fragment>
    </div>
  );
}

export default FileTables;
