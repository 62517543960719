/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-12 10:43:17
 * @LastEditTime: 2023-10-26 11:07:37
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/QuickAccess/Card/index.js
 *
 */
import cooperConfirm from '@/components/common/CooperConfirm';
import { FileType, TeamTag } from '@/constants/cooper';
import GetHtml from '@/utils/DOMPurify';
import { QUICK_ACCESS } from '@/constants/cooperConstants';
import { setImgUrl } from '@/utils/cooperutils';
import { handleFileClick as goToPath } from '@/utils/file';
import { Tooltip } from 'antd';
import classBind from 'classnames/bind';
import { intl } from 'di18n-react';
import { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import RemoveQuick from '../../CooperOperation/RemoveQuick';
import OperateMenu from '../../OperateMenu';
import { updateCurrentList } from '../../RecentActivity/index';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const SHARE_INVALID = 'Invalid';
const SHARE_EXPIRED = 'Expired';

function Card({
  idx = 0,
  file = {},
  switchOrder = () => {},
  refresh = () => {},
  filesList = [],
}) {
  const [, setRefresh] = useState(0);
  const [showUserTip, setShowUserTip] = useState(false);
  const [svgData, setSvgData] = useState(null);
  // const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!file) return;
    const src = file.tiny_image || file.tinyImage;
    if (!src) return;
    if (file.mimeType === 9) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', src, true);
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          const svgContent = xhr.responseText;
          setSvgData(GetHtml(svgContent))
        } else {
          setSvgData(null);
        }
      };
      xhr.send();
    } else {
      setSvgData(null);
    }
  }, [file])

  // 处理拖拽
  const ref = useRef(null);
  const cardTextRef = useRef(null);

  const [, drop] = useDrop({
    accept: 'DragDropBox',
    hover: (item) => {
      if (!ref.current) return;
      let dragIndex = item.idx;
      let hoverIndex = idx;
      if (dragIndex === hoverIndex) return;
      item.idx = hoverIndex;
      switchOrder(dragIndex, hoverIndex);
      window.__OmegaEvent('ep_home_quickaccess_dragmove_ck');
    },
    drop: () => {},
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'DragDropBox',
    item: { idx },
    end: () => {
      setRefresh(Date.now());
    },
    isDragging: (monitor) => {
      return idx === monitor.getItem().idx;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  function isExpired() {
    return (
      file?.isDeleted
      || file?.shareStatus === SHARE_INVALID
      || file?.shareStatus === SHARE_EXPIRED
    );
  }

  useEffect(() => {
    const { clientWidth, scrollWidth } = cardTextRef.current;
    if (scrollWidth > clientWidth) {
      setShowUserTip(true);
    }
  }, [idx]);

  // TODO：知识库和空间可以添加到快速访问吗？
  function isShowUser() {
    return (
      file?.objectType === FileType.SPACE
      || file?.objectType === FileType.KNOWLEDGE
    );
  }

  function handleFileClick() {
    window.__OmegaEvent('ep_home_quickaccess_ck', '', {
      resourceid: file.objectId,
      resource_id: file.objectId,
      position: idx,
      platform: 'new',
    });

    if (isExpired()) {
      let title = intl.t('该内容已被删除或失效');
      let content = intl.t(
        '该内容已被删除或失效，无法继续访问，是否从快速访问列表移出该文件？',
      );
      if (title) {
        cooperConfirm({
          width: 480,
          title,
          content,
          closable: true,
          icon: null,
          okText: intl.t('移出“快速访问”'),
          cancelText: intl.t('取消'),
          onOk: () => {
            RemoveQuick({ id: file.quickId, doneCallback: operateCallBack });
          },
        });
      }
    } else {
      let fileTypeStr = file.objectType;
      let resourceId;
      let resourceName;
      let knowledgeId;
      let dkShareType;
      let pageId;
      let shareId;
      let shareLink;
      let markedQuickVisit;
      let teamId;
      let spaceType;
      let quickId;
      let parentId;
      let spaceId;
      let shareType;
      let fidx;
      switch (file.objectType) {
        // 普通文件
        case FileType.FILE:
          resourceId = file.objectId;
          resourceName = file.objectName;
          shareId = file.shareId;
          shareLink = file.shareLink;
          markedQuickVisit = true;
          teamId = file.sourceId;
          quickId = file.quickId;
          parentId = file?.parentId;
          spaceId = file?.sourceId;
          shareType = file?.shareType;
          fidx = idx;
          break;

        // 文件夹
        case FileType.DIR:
          resourceId = file.objectId;
          teamId = file.sourceId;
          shareId = file.shareId;
          spaceType = file.spaceType === 'PERSONAL_SPACE' ? 'person' : 'team';
          break;

        // 协作文档
        case FileType.COOPER_DOC:
          resourceId = file.objectId;
          break;
        case FileType.SHIMO2_WORD:
          resourceId = file.objectId;
          break;
        case FileType.COOPER_SHEET:
          resourceId = file.objectId;
          break;
        case FileType.SHIMO2_EXCEL:
          resourceId = file.objectId;
          break;
        case FileType.COOPER_SLICE:
          resourceId = file.objectId;
          break;
        case FileType.SHIMO2_PPT:
          resourceId = file.objectId;
          break;
        case FileType.COOPER_ANONYMOUS_DOC:
          resourceId = file.objectId;
          break;

        // 氢文档之后要下线啦
        case FileType.DI_DOC:
          resourceId = file.objectId;
          break;

        // 流程图
        case FileType.FLOWCHART:
          resourceId = file.objectId;
          break;

        // 知识库页面
        case FileType.DK_PAGE:
          dkShareType = file.shareType;
          pageId = file.objectId;
          knowledgeId = file.sourceId;
          shareId = file.shareId;
          shareLink = file.shareLink;
          break;
        case FileType.DK_FILE:
          dkShareType = file.shareType;
          pageId = file.objectId;
          knowledgeId = file.sourceId;
          shareId = file.shareId;
          shareLink = file.shareLink;
          break;
        case FileType.KNOWLEDGE:
          knowledgeId = file.sourceId;
          break;

        // Wiki
        case FileType.WIKI_PAGE:
          resourceId = file.objectId;
          break;

        // 空间
        case FileType.SPACE:
          teamId = file.objectId;
          break;

        default:
          break;
      }
      goToPath({
        fileTypeStr,
        resourceId,
        resourceName,
        knowledgeId,
        dkShareType,
        pageId,
        shareId,
        shareLink,
        markedQuickVisit,
        teamId,
        spaceType,
        quickId,
        spaceId,
        parentId,
        shareType,
        filesList,
        fidx,
      });
    }
  }

  function operateCallBack() {
    refresh();
    updateCurrentList();
  }

  function operateConfig() {
    const { objectType } = file;
    if (
      objectType === FileType.DK_FILE
      || objectType === FileType.WIKI_PAGE
      || objectType === FileType.DK_PAGE
      || objectType === FileType.SPACE
      || objectType === FileType.KNOWLEDGE
    ) {
      return {
        copyLink: false,
        duplicateOperate: false,
        multiSelectOperate: false,
        starOperate: false,
        moveOperate: false,
        copyOperate: false,
        deleteOperate: false,
        shareOperate: false,
        renameOperate: false,
      };
    }
    return {
      duplicateOperate: false,
      multiSelectOperate: false,
      starOperate: false,
      copyLink: false,
    };
  }

  return (
    <div
      className={cx({
        'v3-card': true,
      })}
      ref={drag(drop(ref))}
      style={{ opacity: isDragging ? 0 : 1 }}
      onClick={handleFileClick}
      key={idx}
    >
      {
        (file.mimeType === 9 && svgData) ? (
          <div
            className={cx('v3-card-img')}
            dangerouslySetInnerHTML={{
              __html: GetHtml(svgData),
            }}
           />
        ) : (
          <img
            src={setImgUrl(file)}
            alt=""
          />
        )
      }
      {file?.relationTypeTags
        && file?.relationTypeTags.includes(TeamTag.SPACE_DEPART) && (
          <div className={cx('team-tag')}>{intl.t('部门')}</div>
      )}

      {showUserTip ? (
        <Tooltip
          placement="topLeft"
          title={file?.objectName}>
          <div
            className={cx({
              'v3-card-text': true,
              disable: isExpired(),
            })}
            ref={cardTextRef}
          >
            {file?.objectName}
          </div>
        </Tooltip>
      ) : (
        <div
          className={cx({
            'v3-card-text': true,
            disable: isExpired(),
          })}
          ref={cardTextRef}
        >
          {file?.objectName}
        </div>
      )}

      {isExpired() ? (
        <span className={cx('deleted-tag')}>{intl.t('已失效')}</span>
      ) : (
        isShowUser() && (
          <span className={cx('user-name')}> {file?.owner?.user_cn} </span>
        )
      )}

      {!isExpired() && (
        <span className={cx('operate-tag')}>
          <OperateMenu
            file={file}
            trigger='click'
            config={operateConfig()}
            doneCallback={operateCallBack}
            originFileType={QUICK_ACCESS}
            isFlowChart={file.objectType === FileType.FLOWCHART}
            key={Date.now()}
            isTeam={file.spaceType === 'TEAM_SPACE'}
          />
        </span>
      )}
    </div>
  );
}

export default Card;
