import { intl } from 'di18n-react';
/**
 * Created by Eric on 05/01/2018.
 */
export const FILE = 0;
export const Dir = 1;
export const Coop = 2;
export const DiDoc = 3;
export const FlowChart = 4;

export const RECENT_LIST = 0;
export const CREATE_LIST = 1;
export const QUICK_ACCESS = 2;
export const PERSONAL_TEAM = 3;
export const SHARE_FROM_ME = 4;
export const SHARE_WITH_ME = 5;
export const STAR = 6;
export const TRASH_CAN = 7;
export const SEARCH_RESULT = 8;
export const FILE_PREVIEW = 9;

// 团队及个人已经分开，此处重新定义二者常量
export const MY_COOPER = 9;
export const TEAM_COOPER = 10;

// 团队空间，展示协作的操作权限常量
export const TEAM_OPREATION_TYPE = 'teamSpace';

// 不使用mimetype文档分类
export const ShiMoWord = 2;
export const ShiMoAnonymousWord = 6;
export const ShiMoExcel = 3;
export const ShiMoPPT = 5;
export const DiDocWord = 4;

/* eslint-disable camelcase */
export const Event_COOPER_FILE_HIDE_MODAL = 'cooperFileHideModal';
export const Event_SHARE_FROM_ME_HIDE_MODAL = 'shareFromMeHideModal';

// shimo file types
export const collaborativeDocumentTypes = {
  anonymousWord: 'shimoAnonymousWord',
  word: 'shimoWord',
  word2: 'SHIMO2_WORD',
  excel: 'shimoExcel',
  excel2: 'SHIMO2_EXCEL',
  slide: 'shimoPPT',
  slide2: 'SHIMO2_PPT',
  didoc: 'didocWord',
};

export const Display_Name_Most_Common_Length = 16;
export const Display_Name_Trash_Can_Length = 14;

/* 新功能引导类型 */
export const NewsType = {
  BUBBLE: 'BUBBLE',
  INSTRUCTION: 'INSTRUCTION',
  MARKETING: 'MARKETING',
  POPUP: 'POPUP',
};

export const Mirror_Event = {
  UPLOAD: {
    category: ('用户行为事件'),
    action: ('上传'),
    name: ('上传'),
    value: 1,
  },
  DOWNLOAD: {
    category: ('用户行为事件'),
    action: ('下载'),
    name: ('下载'),
    value: 1,
  },
  SEARCH: {
    category: ('用户行为事件'),
    action: ('搜索'),
    name: ('搜索'),
    value: 1,
  },
  SHARE_WITH_LINK: {
    category: ('用户行为事件'),
    action: ('链接分享'),
    name: ('链接分享'),
    value: 1,
  },
  SHARE_TO_USERS: {
    category: ('用户行为事件'),
    action: ('分享到用户'),
    name: ('分享到用户'),
    value: 1,
  },
  LINK_DOWNLOAD: {
    category: ('用户行为事件'),
    action: ('链接下载'),
    name: ('链接下载'),
    value: 1,
  },
  DELETE: {
    category: ('用户行为事件'),
    action: ('删除'),
    name: ('删除'),
    value: 1,
  },
  DELETE_ROLLBACK: {
    category: ('用户行为事件'),
    action: ('回收站回滚'),
    name: ('回收站回滚'),
    value: 1,
  },
  MARK: {
    category: ('用户行为事件'),
    action: ('收藏'),
    name: ('收藏'),
    value: 1,
  },
  CREATE_FOLD: {
    category: ('用户行为事件'),
    action: ('创建文件夹'),
    name: ('创建文件夹'),
    value: 1,
  },
  HELP: {
    category: ('用户行为事件'),
    action: ('查看帮助'),
    name: ('查看帮助'),
    value: 1,
  },
  LOGOUT: {
    category: ('用户行为事件'),
    action: ('退出'),
    name: ('退出'),
    value: 1,
  },
  BATCH_OPERATION: {
    category: ('用户行为事件'),
    action: ('批量操作'),
    name: ('批量操作'),
    value: 1,
  },
  send: (e) => {
    const obj = Mirror_Event[e];
    if (taotieCommandQueue) {
      taotieCommandQueue.push({
        command: 'sendEventlogMessageToKafka',
        parameter: obj,
      });
    }
  },
};

export function startEndMapping(content, displayLength) {
  return (
    `${content.substring(0, displayLength / 2 - 3)
    }...${
      content.slice(-displayLength / 2)}`
  );
}

export const tooltipStatusEnum = {
  hide: 0,
  hint: 1,
  hintAndOperation: 2,
};

// 用于分页接口中返回全部数据
export const MAX_COUNT = 1000000;

// 成员类型（用户：0; OE组：1; Cooper团队：2; 邮件组：3; DC群组：4）
export const MEMBER_TYPE = {
  USER: 0,
  OE: 1,
  COOPER: 2,
  MAIL: 3,
  DC: 4,
};

export const EpClickShareBatchAddFns = {
  clickBatchAdd: () => window.__OmegaEvent('ep_filesshare_sharespecific_batchadd_ck', '', { platform: 'new' }),
  notify: () => window.__OmegaEvent('ep_filesshare_sharespecific_batchadd_notify_ck', '', { platform: 'new' }),
  return: () => window.__OmegaEvent('ep_filesshare_sharespecific_batchadd_return_ck', '', { platform: 'new' }),
  modifyPermission: () => window.__OmegaEvent(
    'ep_filesshare_sharespecific_batchadd_modifypermission_ck', '', { platform: 'new' },
  ),
  modify: () => window.__OmegaEvent('ep_filesshare_sharespecific_batchadd_modify_ck', '', { platform: 'new' }),
  confirm: () => window.__OmegaEvent('ep_filesshare_sharespecific_batchadd_confirm_ck', '', { platform: 'new' }),
  copy: () => window.__OmegaEvent('ep_filesshare_sharespecific_batchadd_copy_ck', '', { platform: 'new' }),
};

export const EpClickAddMemberBatchAddFns = {
  clickBatchAdd: () => window.__OmegaEvent('ep_teamspace_addmember_batchadd_ck', '', { platform: 'new' }),
  notify: () => window.__OmegaEvent('ep_teamspace_addmember_batchadd_notify_ck', '', { platform: 'new' }),
  return: () => window.__OmegaEvent('ep_teamspace_addmember_batchadd_return_ck', '', { platform: 'new' }),
  modifyPermission: () => window.__OmegaEvent('ep_teamspace_addmember_batchadd_modifypermission_ck', '', { platform: 'new' }),
  modify: () => window.__OmegaEvent('ep_teamspace_addmember_batchadd_modify_ck', '', { platform: 'new' }),
  confirm: () => window.__OmegaEvent('ep_teamspace_addmember_batchadd_confirm_ck', '', { platform: 'new' }),
  copy: () => window.__OmegaEvent('ep_teamspace_addmember_batchadd_copy_ck', '', { platform: 'new' }),
};
