/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-19 15:41:05
 * @LastEditTime: 2023-11-20 10:57:52
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/constants/userView.js
 *
 */
import { ASIDE_SHOW_TYPE, COOPER_ASIDE_TYPE } from '@/constants';
import { SPACE_FILE_SORT, PERSON_FILE_SORT, SHARE_FILE_SORT, HOME_FILE_TYPE_RECENT, HOME_FILE_TYPE_EDIT, FAVORITE_FILE_SORT, HOME_FILE_TYPE_RECENT_TENANT, HOME_FILE_TYPE_EDIT_TENANT } from '@/constants/cooper';
import { getCookie } from '@/utils';
// import { isDiDiTenant } from '@/utils/entryEnhance'

// 获取租户
const tenantId = getCookie('X-Tenant-Id');
const IsExternalTenant = tenantId && tenantId !== 1
/**
 * 用户操作行为默认配置，从localstorage中get、set时参照此json格式
 * 0: false, 1: true, null: 没有设置。非布尔时自行设置并进行注释
 * 注：默认值确定时直接设置为0/1，null表示用户没有设置过，
 */
const USER_VIEW = {
  My_Knowledge: { // 0：创建时间 1：访问时间 2：参与时间
    Sort_Type: {
      MY_OWN: '0',
      MY_JOIN: '2',
    },
  }, // 功能下掉了，暂不生效，后续放开需要改造
  MH_Knowledge: {
    Sort_Type: {},
  }, // 功能下掉了，暂不生效，后续放开需要改造
  HOME_PAGE: {
    Quick_hidden: {},
  }, // 暂时没有关闭快速访问的功能
  MY_SPACE_FILE: { // 个人空间文件列表相关
    sort: {
      ...PERSON_FILE_SORT,
    },
    view: 'TREE',
  },
  Team_SPACE_FILE: { // 团队空间文件列表相关
    sort: {
      ...SPACE_FILE_SORT,
    },
    view: 'TREE',
  },
  SHARE_TO_ME_FILE: { // 分享给我文件列表相关
    sort: {
      ...SHARE_FILE_SORT,
    },
  },
  SHARE_FORM_ME_FILE: { // 我分享的文件列表相关
    sort: {
      ...SHARE_FILE_SORT,
    },
  },
  FAVORITE_FILE: { // 我分享的文件列表相关
    sort: {
      ...FAVORITE_FILE_SORT,
    },
  },
  TEAM_SPACE_INIT: 1, // 是否初次进入空间 功能下掉了，暂不生效，后续放开需要改造
  Aside_Type_Cooper: COOPER_ASIDE_TYPE.LARGE, // 侧边栏类型
  Edit_Page: {
    Aside_Type: ASIDE_SHOW_TYPE.TILE,
  }, // 知识库的 不生效 后续需要改造
  Read_Page: {
    Dictionary_State: null,
  }, // 知识库的 不生效 后续需要改造
  New_Trash_Person_Close: null, // 第一次删除个人文件时提示
  New_Trash_Team_Close: null, // 第一次删除团队文件时提示
  GUIDE_DIALOG: false, // 是否显示新人引导
  GUIDE_DIALOG_SPACE: false, // 访问团队空间时新手引导
  TEAM_TRASH_CLOSE: false, // 团队回收站顶部提示是否展示
  PERSON_TRASH_CLOSE: { // 个人回收站顶部提示是否展示
    file: false,
    space: false,
  },
  syncDiskConfirmed: false, // 同步盘权限提示
  MyRecentFilter: IsExternalTenant ? HOME_FILE_TYPE_RECENT_TENANT : HOME_FILE_TYPE_RECENT, // 最近访问文档类型
  MyEditorFilter: IsExternalTenant ? HOME_FILE_TYPE_EDIT_TENANT : HOME_FILE_TYPE_EDIT, // 最近编辑文档类型
  MyRecentOwn: 'all', // 最近访问归属人
  MyEditOwn: 'all', // 最近编辑归属人,
  Survey_Open_2024_Q1: true,
  TeamChooseType: '1',
  DkChooseType: '1'
}
export default USER_VIEW;

