import { get, postWithTrace } from '@/utils/request';
import api from '@/utils/request/api/DkApi';
import CooperApi from '@/utils/request/api/CooperApi';
import { encodeURIParamFn } from '@/utils';

let i = 0;

export async function getSearchDataOld(params) {
  try {
    const res = await get(api.GET_SEARCH_DATA, { params });
    res.pages && res.pages.items.map((v) => {
      v.random = i;
      return null;
    });
    i++;
    if (!res.searchType) {
      res.searchType === params.searchType;
    }
    res.keyWord = params.key;
    return res;
  } catch (e) {
    console.log(e);
  }
}


export async function getSearchData(params) {
  try {
    const res = await postWithTrace(api.GET_SEARCH_DATA_V2, params);
    res.pages && res.pages.items.map((v) => {
      v.random = i;
      return null;
    });
    i++;
    if (!res.searchType) {
      res.searchType === params.searchType;
    }
    res.keyWord = params.key;
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function getSearchDataDK(par) {
  try {
    const res = await get(`${api.GET_SEARCH_DATA_DK}${encodeURIParamFn(par)}`);
    res.pages && res.pages.items.map((v) => {
      v.random = i;
      return null;
    });
    i++;
    return res;
  } catch (e) {
    console.log(e);
  }
}



export async function getRecentDataForSearch(params) {
  try {
    const res = await get(CooperApi.GET_RECENT_DATA_SEARCH,
      {
        params,
        headers: {
          'X-App-Id': 2,
        },
      });
    return res || [];
  } catch (e) {
    console.log(e);
  }
}

export async function getRecentData(params) {
  try {
    const res = await get(api.GET_RECENT_DATA, { params });
    res && res.map((v) => {
      v.random = i;
      return null;
    });
    i++;
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function getVisitedDK({ maxSize }) {
  try {
    const res = await get(api.GET_VISIT_DK, {
      params: maxSize ? {
        maxSize,
      } : null,
    });
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function getVisitedDKV2() {
  try {
    const res = await get(api.GET_VISIT_DK);
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function getVisitedTeam(params) {
  try {
    const res = await get(api.GET_VISIT_DK, {
      headers: {
        'X-App-Id': 2,
      },
      params,
    });
    return res;
  } catch (e) {
    console.log(e);
  }
}


export async function searchFromAllTags({ pageId, key }) {
  const res = await get(`${api.TAG_ADD_SEARCH.replace(':pageId', pageId)}?key=${encodeURIComponent(key)}`);
  return res;
}

export async function searchTags(par) {
  const res = await get(`${api.TAG_SEARCH}${encodeURIParamFn(par)}`);
  return res;
}
