export const folder = 'https://img-ys011.didistatic.com/static/cooper_cn/folder.png';
export const folderPerm = 'https://img-ys011.didistatic.com/static/cooper_cn/folderPerm.png';
export const smDoc = 'https://img-ys011.didistatic.com/static/cooper_cn/smDoc.png';
export const smSheet = 'https://img-ys011.didistatic.com/static/cooper_cn/smSheet.png';
export const smSlide = 'https://img-ys011.didistatic.com/static/cooper_cn/smppt2.png';
export const smDocNew = 'https://img-ys011.didistatic.com/static/cooper_cn/smDocNew.png';
export const smSheetNew = 'https://img-ys011.didistatic.com/static/cooper_cn/smSheetNew.png';
export const smSlideNew = 'https://img-ys011.didistatic.com/static/cooper_cn/smpptNew.png';
export const anonymous = 'https://img-ys011.didistatic.com/static/cooper_cn/anonymous.png';
export const diDoc = 'https://img-ys011.didistatic.com/static/cooper_yx/do1_fCV1zXs6wdaR6QEMTxpD';
export const pic = 'https://img-ys011.didistatic.com/static/cooper_cn/pic.png';
export const video = 'https://img-ys011.didistatic.com/static/cooper_cn/video.png';
export const audio = 'https://img-ys011.didistatic.com/static/cooper_cn/video.png';
export const filePdf = 'https://img-ys011.didistatic.com/static/cooper_cn/pdf-2.png';
export const fileDoc = 'https://img-ys011.didistatic.com/static/cooper_cn/word.png';
export const filePPT = 'https://img-ys011.didistatic.com/static/cooper_cn/pdf.png';
export const fileExcel = 'https://img-ys011.didistatic.com/static/cooper_cn/xlsx.png';
export const fileTxt = 'https://img-ys011.didistatic.com/static/cooper_cn/txt.png';
export const fileZip = 'https://img-ys011.didistatic.com/static/cooper_cn/zip.png';
export const wikiFile = 'https://img-ys011.didistatic.com/static/cooper_cn/wik.png';
export const fileFlow = 'https://img-ys011.didistatic.com/static/cooper_cn/flowChart.png';
export const sketch = 'https://img-ys011.didistatic.com/static/cooper_cn/sketch.png';
export const dkPage = 'https://img-ys011.didistatic.com/static/cooper_cn/dkpage.png';
export const dkBook = 'https://img-ys011.didistatic.com/static/cooper_cn/dkbook.png';
export const number = 'https://img-ys011.didistatic.com/static/cooper_cn/numbers.png';
export const keynote = 'https://img-ys011.didistatic.com/static/cooper_cn/keynote.png';
export const numbers = 'https://img-ys011.didistatic.com/static/cooper_cn/numbers.png';
export const app = 'https://img-ys011.didistatic.com/static/cooper_yx/do1_vs7NJUEzpSSgg9XKQ3F8';
export const unknown = 'https://img-ys011.didistatic.com/static/cooper_cn/unknown.png';
export const importAsDoc = 'https://img-ys011.didistatic.com/static/cooper_cn/importAsDoc.png';
export const spacePerson = 'https://img-ys011.didistatic.com/static/cooper_cn/spacePerson.png';
export const spaceTeam = 'https://img-ys011.didistatic.com/static/cooper_cn/spaceTeam.png';
export const uploadFile = 'https://img-ys011.didistatic.com/static/cooper_cn/uploadFile.png';
export const uploadFolder = 'https://img-ys011.didistatic.com/static/cooper_cn/uploadFolder.png';
export const home = 'https://img-ys011.didistatic.com/static/cooper_cn/home.png';
