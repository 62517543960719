/* eslint-disable no-bitwise */
/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-29 19:52:37
 * @LastEditTime: 2024-07-04 15:49:45
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/OperateMenu/index.js
 *
 */
import { intl } from 'di18n-react';
import { useEffect, useMemo, useRef, useState } from 'react';

import AddQuick from '@/components/CooperOperation/AddQuick';
import CancelShare from '@/components/CooperOperation/CancelShare';
import CopyFile from '@/components/CooperOperation/CopyFile';
import Duplicate from '@/components/CooperOperation/CopyFile/duplicate';
import DeleteFile from '@/components/CooperOperation/DeleteFile';
import { doDownload } from '@/components/CooperOperation/DownloadFile';
import MoveFile from '@/components/CooperOperation/MoveFile';
import RemoveQuick from '@/components/CooperOperation/RemoveQuick';
import RenameFile from '@/components/CooperOperation/RenameFile';
import ShareCollab from '@/components/CooperOperation/ShareCollab';
import ShareFile from '@/components/CooperOperation/ShareFile';
import StarFile from '@/components/CooperOperation/StarFile';
import { getPreviewType } from '@/components/FilePreview/main';
import { FileType } from '@/constants/cooper';
import { CREATE_LIST, FILE_PREVIEW, MY_COOPER, PERSONAL_TEAM, QUICK_ACCESS, RECENT_LIST, SEARCH_RESULT, SHARE_FROM_ME, SHARE_WITH_ME, STAR, TEAM_COOPER, TRASH_CAN } from '@/constants/cooperConstants';
import { TEAM_REG_LONG } from '@/constants/reg';
import { docDonwnload, getOperationAuth, getOperationAuthority, getOperationShareAuth, getOperationTrashAuth } from '@/service/cooper/home';
import { getSafeLevel } from '@/service/cooper';

import { getBussinessIds } from '@/service/cooper/moveFile';
import { Divider, Popover, message } from 'antd';
import classBind from 'classnames/bind';
import { noop } from 'lodash-es';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.less';
import { entryEnhance } from '@/utils/entryEnhance'
import SafeTag from '@/components/Tag/SafeTag';
// import { TRASH } from '@/constants/space';

const cx = classBind.bind(styles);

export function isRegularFile(type) {
  // 0：文件，1： 文件夹
  return (
    type === FileType.FILE || type === FileType.DIR || type === 0 || type === 1
  );
}

// 配置选项，默认为true，分享及下载进行全局判断
const configInitial = {
  moveOperate: true, // 移动
  copyOperate: true, // 复制
  renameOperate: true, // 重命名
  deleteOperate: true, // 删除
  quickAccessOperate: true, // 快速访问
  duplicateOperate: true, // 创建副本
  starOperate: true, // 收藏
  multiSelectOperate: true, // 批量操作
  restoreOperate: true, // 恢复
  permanentlyDeleteOperate: true, // 彻底删除
  cancelShareOperate: false, // 取消分享
};

// 文件来源（最近访问，快速访问...）
const ORIGIN_TYPE = {
  [RECENT_LIST]: 'recent',
  [MY_COOPER]: 'personal',
  [TEAM_COOPER]: 'team',
  [SHARE_WITH_ME]: 'shared',
  [SHARE_FROM_ME]: 'isheard',
  [STAR]: 'star',
  [TRASH_CAN]: 'trash',
  [SEARCH_RESULT]: 'search',
  [QUICK_ACCESS]: 'quick',
  [CREATE_LIST]: 'create',
  [PERSONAL_TEAM]: 'team||personal', // 团队和个人还没有分开
  [FILE_PREVIEW]: 'preview',
}

function OperateMenu(props) {
  const {
    file: fileList,
    originFileType,
    doneCallback = [],
    uniqueCallback = [],
    config,
    fileOpreationType,
    // 处理回收站
    isTrash = false,
    isTeamTrash = false,
    trashHandlers = {},
    // 处理权限设置
    teamManage,
    backGround = null,
    isFlowChart = false, // 是否流程图文件
    setBatchMode = () => { },
    trigger = 'click',
    teamsOperationType = '',
    isTeam,
    teamId,
  } = props;

  // 生成组件的配置属性
  const {
    moveOperate,
    copyOperate,
    renameOperate,
    deleteOperate,
    quickAccessOperate,
    duplicateOperate,
    starOperate,
    multiSelectOperate,
    permanentlyDeleteOperate,
    restoreOperate,
    copyLink,
    cancelShareOperate,
    shareOperate,
  } = { ...configInitial, ...config };
  // const name = useMemo(() => {
  //   return props.file.resourceName;
  // }, [props]);
  const popoverRef = useRef(null);
  const [file, setFile] = useState(fileList || {});
  const [visible, setVisible] = useState(false);
  const [safeLevel, setSafeLevel] = useState({});
  const [apiDownloadOperate, setApiDownloadOperate] = useState(downloadOperate);
  const [apiRenameOperate, setApiRenameOperate] = useState(renameOperate);
  const [apiDeleteOperate, setApiDeleteOperate] = useState(deleteOperate);
  const [apiShareOperate, setApiShareOperate] = useState(shareOperate);
  const [apiCopyOperate, setApiCopyOperate] = useState(copyOperate);
  const [apiMoveOperate, setApiMoveOperate] = useState(moveOperate);
  const [apiRestoreOperate, setApiRestoreOperate] = useState(restoreOperate);
  const [apiRemoveOperate, setApiRemoveOperate] = useState(permanentlyDeleteOperate);
  const { asideType, userViewData } = useSelector((state) => state.GlobalData);
  const { setToastPersonTrashTip, setToastTeamTrashTip, setUserViewDataRq } = useDispatch().GlobalData;
  // 普通文件才可以下载 0 File 1 Dir 2 shimo 3 didoc(老type)
  const isCanDownload = () => {
    if (originFileType === QUICK_ACCESS) {
      return (
        file.resourceTypeStr === FileType.FILE
        || file.objectType === FileType.FILE
        || file.resourceTypeStr === FileType.DIR
        || file.objectType === FileType.DIR
      );
    }
    return !(
      file.type === 2
      || file.type === 3
      || file.resourceType === 2
      || file.resourceType === 3
    );
  };

  const downloadOperate = config && config.downloadOperate === false ? false : isCanDownload();

  useEffect(() => {
    setFile(fileList);
  }, [fileList]);

  // 团队分享
  const teamShareOperate = useMemo(() => {
    return file.canShare || file.can_share;
  }, [file.canShare, file.can_share]);

  // 个人分享
  const persionShareOprate = useMemo(() => {
    return !(shareOperate === false);
  });

  // 团队权限管理
  const teamManageOperate = file.canManage && typeof teamManage === 'function';

  function runCallbacks(unique) {
    if (Array.isArray(doneCallback)) {
      doneCallback.forEach((item) => typeof item === 'function' && item());
    } else {
      doneCallback && doneCallback(); // eslint-disable-line
    }

    // 处理某个操作的专有回调
    if (unique && typeof uniqueCallback[unique] === 'function') {
      uniqueCallback[unique]();
    }
  }


  // 判断是不是知识库
  function isDkPage() {
    let type = file.resourceTypeStr || file.objectType || file.space_resource_type;
    return (
      // file.resourceTypeStr === FileType.DK_PAGE || file.objectType === FileType.DK_PAGE
      // || file.resourceTypeStr === FileType.DK_FILE
      // || file.objectType === FileType.DK_FILE || file.space_resource_type === FileType.DK_PAGE
      type === FileType.DK_PAGE || type === FileType.DK_FILE
    );
  }

  function isCooperDoc() {
    let type = file.resourceTypeStr || file.objectType || file.space_resource_type;
    return (
      type === FileType.COOPER_DOC || type === FileType.COOPER_SHEET
      || type === FileType.COOPER_SLICE || type === FileType.SHIMO2_WORD
      || type === FileType.SHIMO2_EXCEL || type === FileType.SHIMO2_PPT
    );
  }

  function isDidoc() {
    let type = file.resourceTypeStr || file.objectType || file.space_resource_type;
    return type === FileType.DI_DOC;
  }

  // 添加快速访问
  function _addQuick(e) {
    setVisible(false);
    e.stopPropagation();
    const params = {
      objectType: file.resourceTypeStr || file.space_resource_type || file.objectType || file.resource_type,
      objectId: file.resourceId || file.id,
      sourceAppId: isDkPage() ? 4 : 2,
      sourceId:
        file.teamId
        || file.sourceId
        || file.parent_id
        || file.spaceId
        || file.space_id,
      shareId: file.shareId,
      shareType: file.shareType,
      doneCallback: (quickId) => {
        if (uniqueCallback.handelQuickAccess) {
          uniqueCallback.handelQuickAccess(quickId)
        } else {
          runCallbacks()
        }
        setFile((data) => ({
          ...data,
          quickId,
        }));
      },
    };
    AddQuick(params)
  }

  // 移除快速访问
  function _removeQuick(e) {
    setVisible(false);
    e.stopPropagation();
    RemoveQuick({
      id: file.quickId || file.quick_id,
      doneCallback: () => {
        if (uniqueCallback.handelQuickAccess) {
          uniqueCallback.handelQuickAccess(null)
        } else {
          runCallbacks()
        }
        setFile((data) => ({
          ...data,
          quickId: null,
          marked_quick_visit: false,
        }));
      },
    })
  }
  // 移动文件
  function _moveFile(e) {
    setVisible(false);
    e.stopPropagation();
    // const isShareToMe = file.parentLocationId === -1;
    // if ((file.shareType && file.shareType !== '') || isShareToMe) {
    //   message.error(intl.t('您没有权限'));
    //   return;
    // }
    // // 判断权限，todo 是否可以统一
    // if (file.permission && !checkPermis('write', file.permission)) {
    //   message.error(intl.t('您没有权限'));
    //   return;
    // }
    // 处理团队空间无法跨空间移动
    // TODO: 需要兼容/team-file/xxx/home的逻辑吗？
    // const m = window.location.pathname.match(/^\/team-file\/(\d+)\/?\d*$/);
    const m = window.location.pathname.match(TEAM_REG_LONG);

    let teamId = file.team_id || file.teamId || file.sourceId || 0;
    // let teamId = file.team_id || file.teamId || 0 || file.parentId || file.parent_id;
    if (m) {
      teamId = m[1];
    }
    window.__OmegaEvent('ep_listmore_moveto_ck', '', {
      platform: 'new',
      source: ORIGIN_TYPE[originFileType],
    });
    MoveFile([file], teamId, () => runCallbacks('moveOperate'), '_', !isTeam);
  }

  // 复制文件
  function _copyFile(e) {
    setVisible(false);
    e.stopPropagation();
    // if (file.shareType && file.shareType !== '') {
    //   message.error(intl.t('您没有权限'));
    //   return;
    // }

    // if (file.permission && !checkPermis('write', file.permission)) {
    //   message.error(intl.t('您没有权限'));
    // }
    window.__OmegaEvent('ep_listmore_copyto_ck', '', {
      platform: 'new',
      source: ORIGIN_TYPE[originFileType],
    });
    CopyFile(
      [file],
      () => runCallbacks('copyOperate'),
      () => { },
      !isTeam,
    );
  }

  function _duplicate(e) {
    setVisible(false);
    e.stopPropagation();
    let parentId;
    let teamId;
    if (file.parent) {
      const { id, teamId: _teamId } = file.parent;
      [parentId, teamId] = [id, _teamId];
    } else {
      const { parent: id, teamId: _teamId } = getBussinessIds();
      [parentId, teamId] = [id, _teamId];
    }
    window.__OmegaEvent('ep_listmore_duplicate_ck', '', {
      platform: 'new',
      source: isTeam ? 'team' : 'personal',
    });

    Duplicate([file.id], parentId, teamId, runCallbacks);
  }

  function _renameFile(e) {
    setVisible(false);
    e.stopPropagation();
    // if (file.shareType && file.shareType !== '') {
    //   message.error(intl.t('您没有权限'));
    //   return;
    // }

    // if (file.permission && !checkPermis('write', file.permission)) {
    //   message.error(intl.t('您没有权限'));
    //   return;
    // }
    RenameFile(
      file.resourceTypeStr || file.objectType || file.type,
      file.resourceId || file.objectId || file.id,
      file.resourceName || file.objectName || file.name,
      runCallbacks,
    );
  }

  function _deleteFile(e) {
    setVisible(false);
    e.stopPropagation();
    window.__OmegaEvent('ep_listmore_delete_ck', '', {
      platform: 'new',
      source: ORIGIN_TYPE[originFileType],
    });
    DeleteFile(
      [file.resourceId || file.objectId || file.id],
      () => {
        runCallbacks('deleteOperate');
        const data = { ...userViewData };


        if (isTeam && data.New_Trash_Team_Close !== '-1') {
          data.New_Trash_Team_Close = false;
        } else if (!isTeam && data.New_Trash_Person_Close !== '-1') {
          data.New_Trash_Person_Close = false;
        }
        if (JSON.stringify(userViewData) === '{}') return;
        setUserViewDataRq(data);
      },
      isTeam,
      teamId || file.team_id || file.teamId || file.sourceId || file.spaceId || 0,
    );
  }

  function _cancelShare(e) {
    e.stopPropagation();
    CancelShare({
      shareId: file.shareId,
      shareType: file.shareType,
      ids: [file.id],
      runCallbacks,
    });
  }

  function _starFile(e) {
    setVisible(false);
    e.stopPropagation();
    // XXX: permission
    StarFile(
      [
        {
          ...file,
        },
      ],
      () => runCallbacks('starOperate'),
    );
  }

  function _share(e) {
    setVisible(false);
    e.stopPropagation();

    if (isRegularFile(file.resourceTypeStr || file.objectType || file.type)) {
      window.__OmegaEvent('ep_listmore_share_ck', '', {
        platform: 'new',
        source: isTeam ? 'team' : 'personal',
      });

      // 普通文件、文件夹
      // XXX: teamName ?
      ShareFile(
        file.resourceId || file.objectId || file.id,
        file.resourceName || file.objectName || file.name,
        undefined,
        file.relationTypeTags,
        noop,
      );
    } else {
      window.__OmegaEvent('ep_listmore_cooperateshare_ck', '', {
        platform: 'new',
        source: isTeam ? 'team' : 'personal',
      });
      // 协作文档
      ShareCollab(file.resourceId || file.objectId || file.id, {
        // todo 确认mimeType是否存在，如果存在，顺序必须在前，否则会覆盖file的mime_type字段
        mime_type: file.mimeType || file.mime_type,
        ...file,
        fileName: file.resourceName || file.objectName || file.name,
        type: file.resourceType || file.objectType || file.type,
        isFlowChart,
      });
    }
  }

  function _download(e) {
    setVisible(false);
    e.stopPropagation();
    if (isCooperDoc()) {
      docDonwnload(file.id || file.resource_id || file.resourceId || file.objectId).then((url) => {
        window.location = url;
      });
    } else {
      const shareId = file.shareId || file.share_id || '';
      const shareLink = file.shareLink || file.share_link || '';
      const type = getPreviewType(file);
      // const m = location.pathname.match(/^\/team-file\/(\d+)\/?\d*$/);
      const m = window.location.pathname.match(TEAM_REG_LONG);

      let _teamId = 0;
      if (m) {
        _teamId = m[1];
      }
      let teamId = null;
      if (originFileType === QUICK_ACCESS || originFileType === RECENT_LIST) {
        teamId = file.teamId
          || file.team_id
          || _teamId
          || file.spaceId
          || file.sourceId
          || file.parent_id
          || file.parentId;
      } else {
        teamId = file.teamId || file.team_id || _teamId;
      }
      const ids = file.id || file.resource_id || file.resourceId || file.objectId;
      doDownload(type, [ids], shareId, teamId, window.__connectSiteId);
    }
    window.__OmegaEvent('ep_listmore_download_ck', '', {
      platform: 'new',
      source: ORIGIN_TYPE[originFileType],
    });
  }

  function _deleteForever(e) {
    setVisible(false);
    e.stopPropagation();
    trashHandlers.delete([file], teamsOperationType);
  }

  function _restore(e) {
    setVisible(false);
    e.stopPropagation();
    trashHandlers.restore([file], teamsOperationType);
  }

  function copyText() {
    const { spaceType, shareType, star_type } = file;
    let text = `${window.location.origin}`;
    if (spaceType === 'WIKI_SPACE') {
      if (process.env.APP_ENV === 'prod') {
        text = `http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=${file.teamId}`;
      } else {
        text = `http://wiki-qa.intra.xiaojukeji.com/pages/viewpage.action?pageId=${file.teamId}`;
      }
    }
    if (spaceType === 'METIS_SPACE') {
      if (shareType === 'KNOWLEDGE_SHARE' || shareType === 'DK_SHARE') {
        text += `/knowledge/share/book/${file.shareLink}/${file.resourceId}`;
      } else if (shareType === 'PAGE_SHARE') {
        text += `/knowledge/share/page/${file.shareLink}`;
      } else {
        text += `/knowledge/${file.teamId}/${file.resourceId}`;
      }
    }
    //  处理收藏里面的知识库页面
    // 分享页面
    if (star_type === 'PAGE_SHARE') {
      text += `/knowledge/share/page/${file.star_info}`;
    }
    // 分享知识库
    if (star_type === 'DK_SHARE') {
      text += `/knowledge/share/book/${file.star_info}/${file.resource_id}`;
    }
    // 分享普通知识库页面
    if (star_type === 'RESOURCE') {
      text += `/knowledge/${file.team_id}/${file.resource_id}`;
    }
    return text;
  }

  function getAuth() {
    if (isTrash) {
      getOperationTrashAuth(file.spaceId || teamId, file.resourceId || file.id || file.objectId)
        .then((res) => {
          setApiRestoreOperate(res.canRestore);
          setApiRemoveOperate(res.canRemove);
        })
        .catch(() => {
          message.error(intl.t('获取权限失败'));
        });
    } else if (isTeamTrash) {
      // do nothing
    } else {
      const shareId = file.shareId || file.share_id || '';
      const { shareType } = file;

      if (shareId && shareType) {
        getOperationShareAuth(shareId, shareType, file.resourceId || file.id || file.objectId)
          .then((res) => {
            setApiDownloadOperate(!!(res.perm & 32));
            setApiRenameOperate(!!(res.perm & 2));
            setApiDeleteOperate(!!(res.perm & 4));
            setApiShareOperate(!!(res.perm & 16));
            setApiMoveOperate(res.canMove);
            setApiCopyOperate(res.canCopy);
          })
          .catch(() => {
            message.error(intl.t('获取权限失败'));
          });
      } else {
        getOperationAuth(file.resourceId || file.id || file.objectId)
          .then((res) => {
            setApiDownloadOperate(!!(res.perm & 32));
            setApiRenameOperate(!!(res.perm & 2));
            setApiDeleteOperate(!!(res.perm & 4));
            setApiShareOperate(!!(res.perm & 16));
            setApiMoveOperate(res.canMove);
            setApiCopyOperate(res.canCopy);
          })
          .catch(() => {
            message.error(intl.t('获取权限失败'));
          });
      }
    }
  }

  function reqSafeLevel() {
    getSafeLevel({ resourceId: file.id || file.resource_id || file.resourceId || file.objectId }).then((data) => {
      setSafeLevel(data);
    });
  }

  function onVisibleChange(value) {
    setVisible(value);
    if (value && fileOpreationType == 'teamSpace') {
      getOperationAuthority(file.resourceId || file.id || file.objectId)
        .then((res) => {
          const { canShare } = res;
          // 团队空间中列表接口返回的can_share字段无实际作用。
          if (canShare) {
            let newFile = {
              ...file,
              can_share: true,
            };
            setFile(newFile);
          }
        })
        .catch(() => {
          message.error(intl.t('获取权限失败'));
        });
    }
    if (value) {
      getAuth();
      reqSafeLevel();
    }
  }

  // 统一收藏标识符
  const isStared = file.markedStar || file.marked_star;
  // eslint-disable-next-line no-shadow
  const isInQuickVist = (file) => {
    if (file.quickId) {
      return true;
    }
    return file.markQuickVisit || file.marked_quick_visit;
  };

  const operationMenu = (
    <ul className={cx('v3-operate-menu-operation-menu')}>
      {
        JSON.stringify(safeLevel) !== '{}' && safeLevel?.securityLevel !== 'UNKNOWN'
        && <>
          <li className={cx('hasDivider', 'safe-li')}>
            <SafeTag
              tagkey={safeLevel.resourceId}
              safeTag = {{
                name: safeLevel.securityLevel,
                id: safeLevel.resourceId,
              }}
              overlayClassName={'safe-tag-large'}
              hasBackground = {true}
          />
          </li>

        </>
      }
      {quickAccessOperate
        && (isInQuickVist(file) ? (
          <li
            className={cx('hasDivider')}
            onClick={(e) => _removeQuick(e)}>
            <i
              className={cx(
                'dk-iconfont',
                'dk-icon-yichukuaisufangwen3',
                'add-quick',
              )}
            />

            {intl.t('从“快速访问”移出')}
          </li>
        ) : (
          <li
            className={cx('hasDivider')}
            onClick={(e) => _addQuick(e)}>
            <i
              className={cx(
                'dk-iconfont',
                'dk-icon-kuaisufangwen3px',
                'delete-quick',
              )}
            />

            {intl.t('添加至快速访问')}
          </li>
        ))}

      {quickAccessOperate && !isDkPage() && (
        <Divider className={cx('divider')} />
      )}

      {teamManageOperate && !fileOpreationType && (
        <li
          className={cx('hasDivider', 'teamManage')}
          onClick={(e) => {
            setVisible(false);
            teamManage(e)
          }}
        >
          <div className={cx('left')}>
            <i className={cx('dk-iconfont', 'dk-icon-xiezuo', 'xiezuo-icon')} />
          </div>
          <div className={cx('right')}>
            <p>{intl.t('协作')}</p>
            <p className={cx('desc')}>{intl.t('设置谁可以看、谁可以改')}</p>
          </div>
        </li>
      )}

      {teamManageOperate && !fileOpreationType && (
        <Divider className={cx('divider')} />
      )}

      {file.type == 1 && fileOpreationType == 'teamSpace' && (
        <li
          className={cx('hasDivider', 'teamManage')}
          onClick={(e) => {
            setVisible(false);
            teamManage(e)
          }}
        >
          <div className={cx('left')}>
            <i className={cx('dk-iconfont', 'dk-icon-xiezuo', 'xiezuo-icon')} />
          </div>
          <div className={cx('right')}>
            <p>{intl.t('协作')}</p>
            <p className={cx('desc')}>{intl.t('设置谁可以看、谁可以改')}</p>
          </div>
        </li>
      )}

      {file.type == 1 && fileOpreationType == 'teamSpace' && (
        <Divider className={cx('divider')} />
      )}

      {starOperate && (
        <li onClick={(e) => _starFile(e)}>
          {isStared ? (
            <i
              className={cx(
                'dk-iconfont',
                'dk-icon-yishoucang1',
                'cancel-star-icon',
              )}
            />
          ) : (
            <i
              className={cx('dk-iconfont', 'dk-icon-shoucang1', 'star-icon')}
            />
          )}

          {isStared ? intl.t('取消收藏') : intl.t('收藏')}
        </li>
      )}

      {(downloadOperate && apiDownloadOperate) && !isFlowChart && !isDidoc() && (
        <li onClick={(e) => _download(e)}>
          <i
            className={cx('dk-iconfont', 'dk-icon-xiazai3', 'download-icon')}
          />

          {intl.t('下载')}
        </li>
      )}

      {teamShareOperate && apiShareOperate && fileOpreationType == 'teamSpace' && (
        <li
          className={cx('hasDivider')}
          onClick={(e) => _share(e)}>
          <i className={cx('dk-iconfont', 'dk-icon-fenxiang3', 'share-icon')} />

          {intl.t('分享')}
        </li>
      )}

      {teamShareOperate && fileOpreationType == 'teamSpace' && (
        <Divider className={cx('divider')} />
      )}

      {persionShareOprate && apiShareOperate && !fileOpreationType && (
        <li
          className={cx('hasDivider')}
          onClick={(e) => _share(e)}>
          <i className={cx('dk-iconfont', 'dk-icon-fenxiang3', 'share-icon')} />

          {intl.t('分享')}
        </li>
      )}

      {persionShareOprate && apiShareOperate && !fileOpreationType && (
        <Divider className={cx('divider')} />
      )}

      {moveOperate && apiMoveOperate && !isFlowChart && (
        <li onClick={(e) => _moveFile(e)}>
          <i
            className={cx('dk-iconfont', 'dk-icon-yidongdao3px', 'move-icon')}
          />

          {intl.t('移动到')}
        </li>
      )}

      {copyOperate && apiCopyOperate && !isFlowChart && !isDidoc() && (
        <li onClick={(e) => _copyFile(e)}>
          <i className={cx('dk-iconfont', 'dk-icon-fuzhidao', 'copy-icon')} />

          {intl.t('复制到')}
        </li>
      )}

      {renameOperate && apiRenameOperate && (
        <li onClick={(e) => _renameFile(e)}>
          <i
            className={cx(
              'dk-iconfont',
              'dk-icon-zhongmingming3',
              'reaname-icon',
            )}
          />

          {intl.t('重命名')}
        </li>
      )}

      {entryEnhance(copyLink && (
        <CopyToClipboard
          text={copyText()}
          onCopy={() => {
            setVisible(false);
            message.success(intl.t('已复制链接'));
          }}
        >
          <li>
            <i
              className={cx(
                'dk-iconfont',
                'dk-icon-fuzhilianjie3',
                'reaname-icon',
              )}
            />

            {intl.t('复制链接')}
          </li>
        </CopyToClipboard>
      ))}

      {duplicateOperate && apiCopyOperate && !isFlowChart && (
        <li onClick={(e) => _duplicate(e)}>
          <i
            className={cx(
              'dk-iconfont',
              'dk-icon-chuangjianfuben',
              'copy-demo-icon',
            )}
          />

          {intl.t('创建副本')}
        </li>
      )}

      {multiSelectOperate && !isFlowChart && (
        <li
          className={cx('hasDivider')}
          onClick={(e) => {
            e.stopPropagation();
            setBatchMode(e)
          }}>
          <i
            className={cx(
              'dk-iconfont',
              'dk-icon-piliangcaozuo',
              'all-actions',
            )}
          />

          {intl.t('批量操作')}
        </li>
      )}

      {(deleteOperate && apiDeleteOperate) && <Divider className={cx('divider')} />}

      {(deleteOperate && apiDeleteOperate) && (
        <li
          className={cx('delete-li')}
          onClick={(e) => _deleteFile(e)}>
          <i className={cx('dk-iconfont', 'dk-icon-shanchu3', 'delete-icon')} />

          {intl.t('删除')}
        </li>
      )}

      {
        cancelShareOperate && (
          <li
            className={cx('cancel-share-li')}
            onClick={(e) => _cancelShare(e)}>
            <i className={cx('dk-iconfont', 'dk-icon-quxiaofenxiang')} />

            {intl.t('取消分享')}
          </li>
        )
      }
    </ul>
  );

  const trash = (
    <ul className={cx('v3-operate-menu-operation-menu', 'small')}>
      {restoreOperate && apiRestoreOperate && (
        <li onClick={_restore}>
          <i className={cx('dk-iconfont', 'dk-icon-huifu1')} />

          {intl.t('恢复')}
        </li>
      )}

      {restoreOperate && !apiRestoreOperate && (
        <li className={cx('restore-disabled-li')}>
          <i className={cx('dk-iconfont', 'dk-icon-huifu1')} />
          {intl.t('恢复')}
        </li>
      )}

      {(permanentlyDeleteOperate || apiRemoveOperate) && (
        <li
          onClick={_deleteForever}
          className={cx('delete-li')}>
          <i
            className={cx(
              'dk-iconfont',
              'dk-icon-zujian-shanchu',
              'delete-icon',
            )}
          />

          {intl.t('彻底删除')}
        </li>
      )}
    </ul>
  );

  const teamTrash = (
    <ul className={cx('v3-operate-menu-operation-menu', 'small')}>
      {restoreOperate && (
        <li onClick={_restore}>
          <i className={cx('dk-iconfont', 'dk-icon-huifu1')} />

          {intl.t('恢复')}
        </li>
      )}

      {permanentlyDeleteOperate && (
        <li
          onClick={_deleteForever}
          className={cx('delete-li')}>
          <i
            className={cx(
              'dk-iconfont',
              'dk-icon-zujian-shanchu',
              'delete-icon',
            )}
          />

          {intl.t('彻底删除')}
        </li>
      )}
    </ul>
  );

  const content = isTrash ? trash : (isTeamTrash ? teamTrash : operationMenu);

  return (
    <Popover
      content={content}
      placement="bottomRight"
      overlayClassName="operation-menu-container"
      trigger={trigger}
      onVisibleChange={onVisibleChange}
      visible={visible}
      getPopupContainer={(triggerNode) => triggerNode}
      ref={popoverRef}
      zIndex={99999999}
    >
      {backGround || (
        <div
          className={cx('v3-operate-menu')}
          onClick={(e) => e.stopPropagation()}
          onMouseEnter={() => {
            getAuth()
          }}
        >
          <i
            className={cx('dk-icon-gengduo1', 'dk-iconfont', 'icon-gengduo1')}
          />
        </div>
      )}
    </Popover>
  );
}

export default OperateMenu;
