
import { useEffect, useState, useRef, Fragment } from 'react';
import { Popover, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import classBind from 'classnames/bind';
import { isDesktopDC } from '@/utils';
import { getApolloConfig } from '@/service/knowledge/global';
import RecentKnowledge from './RecentKnowledge';
import config from './config';
import styles from './style.module.less';
import { getLocale } from 'di18n-react';

const cx = classBind.bind(styles);

const jumpTo = (item, data = {}) => {
  if (data.omegaKey) {
    window.__OmegaEvent(data.omegaKey);
  }
  if (isDesktopDC && item.split('//')[1]?.startsWith('im.xiaojukeji.com/channel')) {
    let link = `dchat://im/join_channel?${item.split('?')[1]}`;
    window.open(link);
  } else {
    window.open(item);
  }
};
const iconContent = (list) => {
  if (list?.length) {
    return (
      <ul className={cx('header-icon-list-wrap')}>
        {list.map((v, i) => (
          <li key={i}>
            <p
              onClick={() => {
                v?.link && jumpTo(v.link, v);
              }}
            >
              {v.icon && <img src={v.icon} />}
              {v.text && <span>
                {getLocale() === 'en-US' ? v.textEn : v.text}
              </span>}
            </p>
          </li>
        ))}
      </ul>
    );
  }
};
const localIconContent = (item) => {
  if (item.localType === 'recent') { return <RecentKnowledge />; }
};
const clickRecent = (visible, key) => {
  if (visible && key === 'recent') {
    window.__OmegaEvent('ep_dkpc_dkhead_recent_ck');
  }
};
const clickSuits = (visible, key) => {
  if (visible && key === 'suits') {
    window.__OmegaEvent('ep_dkpc_suitecenter_ck');
  }
};

const Component = ({ knowledgeId }) => {
  const [list, setList] = useState([]);
  const isMounted = useRef();
  const [visible, setVisible] = useState();
  const [modalVisible, setModalVisible] = useState();
  const { knowledgeDetail } = useSelector((state) => state.KnowledgeData);

  useEffect(() => {
    isMounted.current = true;
    (async () => {
      const res = await getApolloConfig({ name: 'DK-header-icon' });
      if (!isMounted.current) {
        return;
      }
      if (res) {
        let list = [...config()];
        Object.entries(res)?.map(([key, value]) => {
          list.push({ ...JSON.parse(value), key });
          return null;
        });
        list = list.filter((v) => !v.needKnowledge || (v.needKnowledge && knowledgeId));
        setList(list);
      }
    })();
    return () => {
      isMounted.current = false;
    };
  }, []);
  const IconContent = (v) => {
    return <div
      className={`${cx('header-icon-wrap')} ${v.hover ? cx('icon-wrap-hover') : ''}`}
      onClick={() => { v?.link && jumpTo(v.link, v); }}
      id={v?.id}
    >
      {v?.icon && (
        <div
          className={`${cx('header-img-wrap')}`}
          style={v.width ? { width: v.width } : null}
        >
          <img
            src={v.icon}
            className={`${cx('header-img-icon')} ${v['icon-active'] ? cx('has-active') : ''} ${(v['icon-visible'] && modalVisible == v.key) ? cx('has-visible') : ''}`}
            style={v.size ? { width: v.size } : {}}
          />
          {v['icon-active'] && (
            <img
              src={v['icon-active']}
              className={`${cx('header-img-icon-active')}`}
              style={v.size ? { width: v.size } : {}}
            />
          )}
          {v['icon-visible'] && (
            <img
              src={v['icon-visible']}
              className={`${modalVisible === v.key ? cx('header-img-icon-visible') : cx('header-img-icon-unvisible')}`}
              style={v.size ? { width: v.size } : {}}
            />
          )}
        </div>
      )}
      {v.text && (
        <span className={`${cx('header-icon-text')}`}>{v.text}</span>
      )}
    </div>;
  };

  return (
    <>
      {list.map((v, i) => {
        const content = (
          <Tooltip
            title={getLocale() === 'en-US' ? v?.tooltipEn : v?.tooltip}
            visible={visible === v.key}
            overlayClassName='dk-ant-tooltip__reset'
            onVisibleChange={(v1) => {
              !modalVisible || modalVisible !== v.key ? setVisible(v1 ? v.key : v1) : setVisible(false);
            }}
          >
            {v.key === 'suits' ? knowledgeDetail.department && IconContent(v) : IconContent(v) }
          </Tooltip>
        );
        return (
          <Fragment key={i}>
            {v.local ? <Popover
              trigger="click"
              overlayInnerStyle={{ minWidth: 104 }}
              overlayClassName={cx('dk-ant-popover__reset', v.overlayClassName)}
              onVisibleChange={(v1) => {
                v1 && setVisible(false);
                setModalVisible(v1 ? v.key : v1);
                clickSuits(v1, v.key);
              }}
              content={v.children}
            >
              {content}
            </Popover>
              : <>{v?.children?.length ? (
                <Popover
                  trigger="click"
                  overlayInnerStyle={{ minWidth: 104 }}
                  onVisibleChange={(v1) => {
                    v1 && setVisible(false);
                    setModalVisible(v1 ? v.key : v1);
                    clickRecent(v1, v.key);
                  }}
                  overlayClassName={cx('dk-ant-popover__reset', { recent: v.localType === 'recent' })}
                  destroyTooltipOnHide={true}
                  content={v.localType ? localIconContent(v) : iconContent(v.children)}
                >
                  {content}
                </Popover>
              ) : (
                content
              )}</>}
          </Fragment>
        );
      })}
    </>
  );
};
export default Component;

