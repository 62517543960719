import { TREE_VIEW_TYPE } from '@/constants/index';
import NotificationStatus from '@/constants/notification';
import useNotification from '@/hooks/useNotification';
import usePermission from '@/hooks/usePermission';
import { getPageOwnerInfo, getPagePermission } from '@/service/knowledge/page';
import { intl } from 'di18n-react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';


export default function useInitDKData({ knowledgeId, pageId, shareId }) {
  const [hasDkPerm, setHasDKPerm] = useState(null);
  const [hasPagePerm, setHasPagePerm] = useState(null);
  const [pageError, setPageError] = useState(null);
  const [pageOwnerInfo, setPageOwnerInfo] = useState({});
  const navigate = useNavigate();
  const notification = useNotification();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { checkOperationPermission } = usePermission();
  const {
    getKnowledgeDetail, setTreeViewType, updateDkOwnerInfo, setHasCompleteLinkPerm,
  } = dispatch.KnowledgeData;


  useEffect(() => {
    setPageError(false);
    if (shareId) return;

    if (!knowledgeId) return;

    getKnowledgeDetail(knowledgeId).then(() => {
      setHasDKPerm(true);
      setTreeViewType(TREE_VIEW_TYPE.PAGE);
      setHasCompleteLinkPerm({
        hasCompleteLinkPerm: true,
        pageId: '0',
      });
      setPageError(false);
    })
      .catch((err) => {
        setPageError(false);
        if (err.errorCode === 501082) { // 没有查看权限时
          setHasDKPerm(false);
          updateDkOwnerInfo(knowledgeId);
        } else if (err.errorCode !== 401) {
          setPageError(true);
          notification(NotificationStatus.ERROR, err.errorMessage || err.errorType);
        }
      });
  }, [knowledgeId]);


  useEffect(() => {
    setPageError(false);
    if (shareId) return;
    if (!pageId) return;

    // TODO:接口加上删除情况后，兼容删除
    // get perm 和 get details 拆开是为了尽快获取权限，没有权限就不需要进行后续操作
    getPagePermission({ pageId }).then((permission) => {
      setPageError(false);
      const hasReadPerm = checkOperationPermission('READ_PAGE', permission.perm);

      setHasPagePerm(hasReadPerm);
      setHasCompleteLinkPerm({
        hasCompleteLinkPerm: permission.hasCompleteLinkPerm,
        pageId,
      });
      if (!hasReadPerm) {
        getPageOwnerInfo(pageId).then((data) => {
          setPageOwnerInfo(data);
        });
        return;
      }
      // 没有编辑权限时，跳转到预览页面
      if (pathname.indexOf('edit') !== -1) {
        const hasEditPerm = checkOperationPermission('MANAGE_PAGE_CONTEXT', permission.perm);
        if (!hasEditPerm) {
          notification(NotificationStatus.ERROR, intl.t('您没有编辑权限，即将跳转到预览页'));
          setTimeout(() => {
            navigate(pathname.replace('/edit', ''));
          }, 1000);
        }
      }
    })
      .catch((err) => {
        setPageError(false);
        if (err.errorCode === 300002) { // 页面被删除
          setHasPagePerm(true);
        } else if (err.errorCode !== 401) {
          setPageError(true);
          notification(NotificationStatus.ERROR, err.errorMessage || err.errorType);
        }
      });
  }, [pageId]);


  const hasPerm = useMemo(() => {
    if (hasDkPerm === null && hasPagePerm === null) return null;
    return pageId ? hasPagePerm : hasDkPerm;
  }, [shareId, hasDkPerm, hasPagePerm]);


  return {
    pageOwnerInfo,
    hasDkPerm,
    hasPagePerm,
    hasPerm,
    pageError,
  };
}

