
import ImportCollab from '@/components/CooperOperation/ImportCollab';
import ShareFile from '@/components/CooperOperation/ShareFile';
import OperateMenu from '@/components/OperateMenu';
import { updateQuicks } from '@/components/QuickAccess/index';
import { getOperationAuth, getOperationShareAuth } from '@/service/cooper/home';
import miniBus from '@/utils/miniBus';
import { Tooltip, message } from 'antd';
import { intl } from 'di18n-react';
import { Fragment, useEffect, useState } from 'react';
import { getSafeLevel } from '@/service/cooper';
import IcDownload from '../icon/v2/icon_xiazai.svg';
import open from '../icon/v3/ic_daoru@2x.png';
import share from '../icon/v3/icon_fenxianng_line@2x.png';
import more from '../icon/v3/icon_gengduo@2x.png';
import SafeTag from '@/components/Tag/SafeTag';
import Tag from '@/components/Tag';
import CustomerService from '@/baseComponents/CustomerService';
import './index.less';

// 操作里面的配置
const config = {
  moveOperate: false,
  copyOperate: false,
  renameOperate: false,
  duplicateOperate: false,
  downloadOperate: false,
  shareOperate: false,
  multiSelectOperate: false,
  starOperate: false,
};


function Header(props) {
  const { fname, file, onClose, state, onDownload, updateImgFiles, refreshFolder, updateShareFiles } = props
  const { type, hasOperation } = state
  const [headerState, setHeaderState] = useState({
    rotate: 0,
    scale: 10,
    convertTip: '',
    apiShareOperate: false,
    apiDownloadOperate: false,
  })
  const [safeLevel, setSafeLevel] = useState({});
  useEffect(() => {
    type === 'newoffice' && getAuth()

    window.addEventListener('message', closeFromIframe);
    return () => {
      window.removeEventListener('message', closeFromIframe);
    }
  }, [type]);

  useEffect(() => {
    if (!file.id) return;
    reqSafeLevel();
  }, [file.id]);

  function reqSafeLevel() {
    getSafeLevel({ resourceId: file.id }).then((data) => {
      setSafeLevel(data);
    });
  }

  // 定义删除 快速访问的回调函数
  const uniqueCallback = {
    deleteOperate: () => {
      onClose();
      miniBus.dispatchEvent('folderCreated');
      if (typeof updateQuicks === 'function') {
        updateQuicks();
      }
      if (typeof refreshFolder === 'function') {
        refreshFolder()
      }
    },
    handelQuickAccess: (quickId) => {
      if (typeof updateQuicks === 'function') {
        updateQuicks();
      }
      if (typeof updateImgFiles === 'function') {
        updateImgFiles(file, quickId);
      }
      if (typeof refreshFolder === 'function') {
        refreshFolder()
      }
      if (typeof updateShareFiles === 'function') {
        updateShareFiles(file);
      }
      miniBus.dispatchEvent('folderCreated');
    },
  };

  // 判断文件所在地是不是团队空间
  const isTeam = (data) => {
    return data.space_type_str === 'TEAM_SPACE' || data.spaceType === 'TEAM_SPACE' || window.location.href.includes('/team-file/')
  }

  // 获取权限信息
  const getAuth = () => {
    const shareId = file.shareId || file.share_id || '';
    const { shareType } = file;

    if (shareId && shareType) {
      getOperationShareAuth(shareId, shareType, file.resourceId || file.id || file.objectId)
        .then((res) => {
          setHeaderState({
            // eslint-disable-next-line no-bitwise
            apiShareOperate: !!(res.perm & 16),
            // eslint-disable-next-line no-bitwise
            apiDownloadOperate: !!(res.perm & 32),
          })
        })
        .catch(() => {
          message.error(intl.t('获取权限失败'));
        });
    } else {
      getOperationAuth(file.resourceId || file.id || file.objectId)
        .then((res) => {
          setHeaderState({
            // eslint-disable-next-line no-bitwise
            apiShareOperate: !!(res.perm & 16),
            // eslint-disable-next-line no-bitwise
            apiDownloadOperate: !!(res.perm & 32),
          })
        })
        .catch(() => {
          message.error(intl.t('获取权限失败'));
        });
    }
  }

  const editByCollab = () => {
    const isSheet = !/\.docx$|\.doc$|\.md$|\.txt$/i.test(fname)
    window.__OmegaEvent(isSheet ? 'ep_filespreview_openwithsheet_ck' : 'ep_filespreview_openwithdoc_ck');
    let parentId = file.parent_id || file.parentId || 0
    let spaceId = file.space_id || file.spaceId || file.team || file.sourceId
    ImportCollab(file, 1, parentId, spaceId, () => {
      // console.log('转换为在线文档');
    });
  }

  const closeFromIframe = (e) => {
    // iframe 中的关闭事件
    if (['formcancel', 'formclose'].includes(e.data.event)) {
      // this.props.onClose();
    }
  };

  const backGround = (
    <div className='more'>
      <img src={more} />
    </div>
  );

  const shareByPreview = (fileItem) => {
    window.__OmegaEvent('ep_filespreview_share_ck');
    ShareFile(fileItem.id, fileItem.name, undefined, fileItem.relationTypeTags);
  }

  const collabTitle = /\.xlsx$|\.xls$|\.csv$|\.xlsm/i.test(fname)
    ? intl.t('用协作表格打开')
    : intl.t('用协作文档打开');

  const _getFamilyByType = (securityLevel) => {
    if (!securityLevel) return;
    if (securityLevel === 'UNKNOWN') return 'safety-level-2';
    if (securityLevel.indexOf('1') !== '-1' || securityLevel.indexOf('2') !== '-1') return 'safety-level-2';
    if (securityLevel.indexOf('3') !== '-1') return 'safety-level-3';
    if (securityLevel.indexOf('4') !== '-1') return 'safety-level-4';
    if (securityLevel.indexOf('5') !== '-1') return 'safety-level-5';
  }


  return <Fragment>
    <div className='header-left'>
      <i
        className='dk-iconfont dk-icon-fanhuiyemian back-icon'
        onClick={onClose} />
      <span className='file-name'>{fname}</span>
      <div className='tag-wrap'>
        {safeLevel.securityLevel && safeLevel.securityLevel !== 'UNKNOWN' && <Tag
          type={_getFamilyByType(safeLevel.securityLevel)}
          text={`${intl.t('数据等级C')}${safeLevel.securityLevel}`}
        />}
        {
          ((file.relationTypeTags || []).includes('IN_OUT')) && <Tag
            type='out-yellow'
            text={intl.t('外部')}
          />
        }
      </div>
    </div>
    <div className='header-right'>

      {/* 用协作文档打开 */}
      {
        /\.docx$|\.doc$|\.md$|\.txt$|\.xlsx$|\.xls$|\.csv$|\.xlsm$/i.test(fname) && (
          <Tooltip
            title={collabTitle}
            placement='bottom'>
            <button
              onClick={editByCollab}
              className='open-in-cooper'>
              <img
                src={open}
                alt='icon-edit' />
              <div className='right-text'>
                {collabTitle}
              </div>
            </button>
          </Tooltip>
        )
      }
      {/* 分享 */}
      {
        headerState.apiShareOperate && (
          <Tooltip
            title={intl.t('分享')}
            placement='bottom'
          >
            <span
              className='button'
              onClick={() => shareByPreview(file)}
            >
              <img
                src={share}
                alt='icon-feedback' />
            </span>
          </Tooltip>
        )
      }
      {/* 下载 只有 excel、word、ppt、txt、pdf 的时候需要下载按钮在上面出现 */}
      {headerState.apiDownloadOperate && type === 'newoffice' && <Tooltip
        title={intl.t('下载')}
        placement='bottom'
      >
        <span
          className='button'
          onClick={() => {
            window.__OmegaEvent('ep_filespreview_download_ck');
            onDownload();
          }}>
          <img
            src={IcDownload}
            alt='icon-download' />
        </span>
      </Tooltip>}
      {
        hasOperation && <OperateMenu
          trigger='hover'
          backGround={backGround}
          key={file.id}
          file={file}
          config={config}
          isTeam={isTeam(file)}
          uniqueCallback={uniqueCallback}
          originFileType={9}
        />
      }

      <CustomerService 
        hideSpliteLine={true}
        hideShowNewFeature={true}
      />

    </div>
  </Fragment>
}

export default Header
