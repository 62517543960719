import { get, post, put, del } from '@/utils/request';
import api from '@/utils/request/api/DkApi';
import { encodeURIParamFn } from '@/utils';

export async function getpageApprovalProgressUrl(payload) {
  const { resourceId, version } = payload
  return get(api.GET_APPROVAL_PROGRESS, {
    params: {
      resourceId,
      version,
    },
  })
}


export function getPageDetail(payload) {
  const { pageId } = payload;
  return get(api.PAGE_DETAIL.replace(':pageId', pageId), { keepSilent: true });
}

// 快照详情
export function getSnapshotDetail(payload) {
  const { pageId, version, mapId } = payload
  return get(api.SNAPSHOT_DETAIL.replace(':pageId', pageId).replace(':version', version).replace(':mapId', mapId), { keepSilent: true })
}


export function getChangeOwnerType(payload) {
  const { type, id } = payload;
  return get(api.GET_CHANGE_OWNER_PERM, {
    params: {
      type,
      id,
    },
  });
}

export function getSharePageDetail(payload) {
  const { pageId, shareId } = payload;

  // 知识库分享，访问某个页面
  if (pageId) {
    return get(api.SHARE_PAGE_DETAIL_BOOK.replace(':shareId', shareId), {
      params: {
        pageId,
      },
    });
  }
  // 页面分享
  return get(api.SHARE_PAGE_DETAIL.replace(':shareId', shareId));
}

export function searchPageMembers(par) {
  return get(`${api.SEARCH_PAGE_MEMBER}${encodeURIParamFn(par)}`);
}

export async function searchNoticeMember(params) {
  const res = await get(api.SEARCH_NOTICE_MEMBER, { params });
  return res
}

export function getPageInheritMembers(params) {
  return get(api.GET_PAGE_INHERIT_MEMBER, { params });
}

export function getPageIndividualMembers(params) {
  return get(api.GET_PAGE_INDIVIDUAL_MEMBER, { params });
}

export function addPageMembers(pageId, params) {
  return post(api.ADD_PAGE_MEMBER.replace(':pageId', pageId), params);
}

export function changePageRole(pageId, params) {
  return put(api.CHANGE_PAGE_ROLE.replace(':pageId', pageId), params);
}

export function delPageMembers(pageId, params) {
  return del(api.DEL_PAGE_MEMBER.replace(':pageId', pageId), { params });
}

export function delPageGroup(pageId, params) {
  return del(api.DEL_PAGE_GROUP.replace(':pageId', pageId), { params });
}

export function addPageGroup(pageId, params) {
  return post(api.ADD_PAGE_GROUP.replace(':pageId', pageId), params);
}

export function releasePage({ pageId, ...params }) {
  return post(api.RELEASE_VERSION.replace(':pageId', pageId), params);
}

// 重新发起审批
export function reissueApproval({ pageId, ...params }) {
  return post(api.INITIATE_APPROVAL.replace(':pageId', pageId), params);
}


export function revertPage({ pageId, ...params }) {
  return post(api.REVERT_VERSION.replace(':pageId', pageId), params);
}

export function getVersionList(pageId, owner) {
  return get(api.VERSION_LIST.replace(':pageId', pageId), { params: { owner }}, { keepSilent: true });
}

export function getPageTags({ pageId }) {
  return get(api.PAGE_TAG_LIST.replace(':pageId', pageId));
}

export function addPageTags(params) {
  return post(api.TAG_ADD, params);
}

export function delPageTags(params) {
  return post(api.TAG_DELETE, params);
}

export function getPageOwnerInfo(pageId) {
  return get(api.PAGE_OWNER_INFO.replace(':pageId', pageId));
}

export function batchAddPageMember(params) {
  return post(api.BATCH_ADD_PAGE_COOPERATORS, params);
}

export function changeInheritType({ pageId, knowledgeId, extensionType }) {
  return post(api.CHANGE_INHERIT_TYPE.replace(':pageId', pageId), { knowledgeId, extensionType });
}

export function reqInheritType({ pageId, knowledgeId }) {
  return get(api.GET_INHERIT_TYPE.replace(':pageId', pageId), { params: { knowledgeId }});
}

export function getPagePermission({ pageId }) {
  return get(api.GET_PAGE_PERMISSION.replace(':pageId', pageId));
}

export function getPreviewUrl(params) {
  return post(api.GET_PREVIEW_URL, params);
}

export function getSnapshotPreviewUrl(params) {
  return post(api.GET_SNAPSHOT_PREVIEW_URL, params)
}

export function getPreviewFileInfo(pageId, params) {
  return get(api.GET_PREVIEW_FILE_INFO.replace(':pageId', pageId), { params });
}

export function downloadFile(knowledgeId, params) {
  return post(api.DOWNLOAD.replace(':knowledgeId', knowledgeId), params);
}
export function transferPageOwner(params) {
  return post(api.TRANSFER_PAGE_OWNER, params);
}

export async function pageVerify(resourceId, params) {
  const res = await get(api.APPLY_PAGE_VERIFY.replace(':resourceId', resourceId), { params });
  return res;
}

export function downloadFileWordPDF(pageId, type, params) {
  return get(api.DOWNLOADWORDPDF.replace(':pageId', pageId).replace(':type', type), { params, keepSilent: true });
}

export function getPageIndividualMembersAloneperm(params) { // 权限:-当前页面单独授权
  return post(api.GET_PAGE_INDIVIDUAL_MEMBER_ALONEPERM, params);
}

export function getPageDetailPageView(pageId) {
  return get(api.GET_PAFE_DETAIL_PAGE_VIEW.replace(':pageId', pageId), { keepSilent: true });
}

export function noticeMember(params) {
  return post(api.NOTICE, params);
}

export function setNoticeTemp(params) {
  return post(api.SET_NOTICE_TEMP, params);
}

export function getNoticeTemp(pageId) {
  return get(api.GET_NOTICE_TEMP.replace(':pageId', pageId));
}

export function updatePageStyle(params) {
  return post(api.PAGE_STYLE, params);
}

export function convertWikiToDidoc(params) {
  return post(api.CONVERT_WIKI_TO_DIDOC, params);
}
