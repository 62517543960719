/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-05 19:58:59
 * @LastEditTime: 2023-06-20 15:31:37
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/service/cooper/index.js
 *
 */
import { get, post, del } from '@/utils/request/cooper';
import CooperApi from '@/utils/request/api/CooperApi';
import { getCookie } from '@/utils';
import { getSign } from '@didi/wsgsig';

export async function getFlowChartUrl(id) {
  const res = await get(CooperApi.RESOURCE_DOC.replace(':resourceId', id), {
    headers: {
      'X-App-Id': 2,
    },
  });
  let docId = res.guid;
  const { fileName } = res;
  return `/docs/flow/draw?docId=${docId}&resourceId=${id}#L${fileName}`;
}

export async function getTopTeamList() {
  const res = await get(CooperApi.TEAMS_PIN);
  return res;
}

export async function setConnectedSiteId() {
  let startTime = new Date().getTime();
  const res = await get(CooperApi.CONNECT_SITE_ID, {
    headers: {
      'X-App-Id': 2,
    },
  })
  let endTime = new Date().getTime();
  window.__OmegaEvent('tech_ep_network_load_time', '知识库核心接口加载时长', {
    source: 'region',
    time: endTime - startTime,
  })
  window.__connectSiteId = res
}

export async function doPinned(teamId) {
  return post(CooperApi.PIN_SPACE.replace(':teamId', teamId));
}

export async function doUnPinned(teamId) {
  return del(CooperApi.PIN_SPACE.replace(':teamId', teamId));
}

export function getCrumbs(id) {
  return new Promise((resolve) => {
    get(CooperApi.DIRECTORY_CRUMBS.replace(':id', id)).then((res) => {
      resolve(res);
    });
  });
}

export function getShareCrumbs({ shareId, id }) {
  return new Promise((resolve) => {
    get(CooperApi.DIRECTORY_CRUMBS_SHARE.replace(':shareId', shareId).replace(':id', id)).then((res) => {
      resolve(res);
    });
  });
}

export const spaceQuotaApply = ({ teamId = 0, surplusQuota, reason }) => {
  return post(CooperApi.SPACE_QUOTA_APPLY.replace(':teamId', teamId), { surplusQuota, reason });
};

export const getSafeLevel = ({ resourceId }) => {
  return get(CooperApi.SPACE_LEVEL_FILE.replace(':resourceId', resourceId));
};

export const getUnreadNotice = () => {
  let params = {
    pageNum: 0,
    pageSize: 10000,
    latestTs: Date.now(),
  };
  let ngis = getSign({
    params,
  });


  return get(CooperApi.API_NOTIFICATIONS_UNREAD, {
    params,
    headers: {
      wsgsig: ngis,
    },
  })
}
export const sendTenantFeedback = (params) => {
  return post(CooperApi.TENANT_FEEDBACK, { ...params });
};


export const getCooperNoticeFromBackEnd = async () => {
  const res = await get(CooperApi.SWITCH_TO_COOPER, {
    headers: {
      'X-User-Token': getCookie('_chain_user_token') || getCookie('_user_token'),
      'X-Username': getCookie('_cooper_username'),
    },
  });

  return res;
}

export const getCooperErrorFromBackEnd = async () => {
  const res = await get(CooperApi.ERROR_NOTICE, {
    headers: {
      'X-User-Token': getCookie('_chain_user_token') || getCookie('_user_token'),
      'X-Username': getCookie('_cooper_username'),
    },
  });

  return res;
}
