/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-01 16:07:48
 * @LastEditTime: 2023-09-20 17:01:32
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperOperation/DeleteFile/index.js
 *
 */
import { intl } from 'di18n-react';
import { message } from 'antd';
import { post } from '@/utils/request/cooper';
import api from '@/utils/request/api/CooperApi';
import './index.less';
import { openNewWindow } from '@/utils/index';
import cooperConfirm from '@/components/common/CooperConfirm';

function doDelete(ids) {
  return post(api.RESOURCES_BATCH_DELETE, ids, {
    headers: {
      'X-App-Id': 2,
    },
  });
}

function goTrash(teamId, isTeam) {
  if (isTeam) {
    openNewWindow(`/team-file/${teamId}/trash`)
  } else {
    openNewWindow('/trash')
  }
}

function confirmTips(isTeam, teamId) {
  const spanText = isTeam ? intl.t('当前团队空间回收站') : intl.t('回收站');
  const text = (
    <span>
      {intl.t('删除的文件会进入')}
      <span
        onClick={() => goTrash(teamId, isTeam)}
        style={{
          color: '#0066FF',
          padding: '0 2px',
          cursor: 'pointer',
        }}>{spanText}</span>
      {intl.t('，30天内可以在回收站进行还原')}
    </span>
  )
  return (
    <ul className="confirm-content-delete">
      <li className="content-li-one">{text}</li>
    </ul>
  );
}

function deleteFile(ids, doneCallback, isTeam, teamId) {
  cooperConfirm({
    type: 'warn',
    zIndex: 2001,
    title: intl.t('是否删除已选择的文件？'),
    content: confirmTips(isTeam, teamId),
    onOk: () => {
      doDelete(ids).then(() => {
        message.success(intl.t('删除成功'));
        doneCallback();
      });
    },
    okText: intl.t('确定'),
    cancelText: intl.t('取消'),
  });
}

export default deleteFile;
