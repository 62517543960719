/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-15 17:04:26
 * @LastEditTime: 2024-04-25 15:03:37
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/common/ImageEnlarger/index.js
 *
 */
import { Popover } from 'antd';
import { useEffect, useState } from 'react';
import GetHtml from '@/utils/DOMPurify'
import { FileType } from '@/constants/cooper';
// import { get } from '@/utils/request/cooper';
import './style.less';

// 鼠标悬浮放大缩略图
function ImageEnlarger({ src, isTiny, mimeType, resourceType }) {
  const [data, setData] = useState(null);
  const [isFlowChart, setIsFlowChart] = useState(false);

  useEffect(() => {
    if (mimeType === 9 || resourceType === FileType.FLOWCHART) {
      // get(src).finally((err) => {
      //   console.log(err);
      //   setData(GetHtml(err))
      // })
      const xhr = new XMLHttpRequest();
      xhr.open('GET', src, true);
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          const svgContent = xhr.responseText;
          setData(GetHtml(svgContent))
        } else {
          setData(null);
        }
      };
      xhr.send();
    } else {
      setData(null);
    }
  }, [mimeType, resourceType, src]);

  useEffect(() => {
    if (mimeType === 9 || resourceType === FileType.FLOWCHART) {
      setIsFlowChart(true);
    } else {
      setIsFlowChart(false);
    }
  }, [mimeType, resourceType]);

  return (
    isTiny ? (
      <Popover
        overlayClassName='image-enlarger'
        content={
          (isFlowChart && data) ? (
            <div
              className='enlarger enlarger-svg'
              dangerouslySetInnerHTML={{
                __html: GetHtml(data),
              }}
             />
          ) : (
            <img
              className='enlarger'
              src={src}
            />
          )
        }
        placement='rightTop'
      >
        <div className='file-name-list-div'>
          {
            (isFlowChart && data) ? (
              <div
                className='file-name-list'
                dangerouslySetInnerHTML={{
                  __html: GetHtml(data),
                }}
               />
            ) : (
              <img
                src={src}
                className='file-name-list'
              />
            )
          }
        </div>
      </Popover>
    ) : (
      <>
        {
          (isFlowChart && data) ? (
            <div
              className='file-name-list'
              dangerouslySetInnerHTML={{
                __html: GetHtml(data),
              }}
               />
          ) : (
            <img
              src={src}
              className='file-name-list'
            />
          )
        }
      </>
    )
  );
}

export default ImageEnlarger;
