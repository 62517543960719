import showProgress from '@/components/CooperOperation/MoveFile/move-modal/m-progress/showProgress';
import openCollob from '@/components/CooperOperation/OpenCollab';
import openDidoc from '@/components/CooperOperation/OpenDidoc';
import openFlowChart from '@/components/CooperOperation/OpenFlowChart';
import openFolder from '@/components/CooperOperation/OpenFolder';
import previewFile from '@/components/CooperOperation/PreviewFile/index';
import { MOVE, MY_COOPER } from '@/constants/cooper';
import { Coop, DiDoc, Dir, FILE, FlowChart } from '@/constants/cooperConstants';
import { doMove } from '@/service/cooper/moveFile';
import { message } from 'antd';
import { intl } from 'di18n-react';

export const FILETYPE = {
  [FILE]: 'file',
  [Dir]: 'dir',
  [Coop]: 'coop',
  [DiDoc]: 'didoc',
  [FlowChart]: 'flowchart',
}

export function findChecks(tree, isListView) {
  // 列表视图
  if (isListView) {
    return (tree.children || []).filter((ch) => ch.checkStatus === 1);
  }
  // 树视图
  if (tree.checkStatus === 1) {
    if (tree.level === 0) {
      return tree.children || [];
    }
    return [tree];
  }

  return (
    (tree.children || [])
      .map((item) => findChecks(item, isListView))
      .reduce((a, b) => [...a, ...b], [])
  );
}

export function findById(tree, id) {
  if (tree.id === id) return tree;

  for (const ch of tree.children || []) {
    const item = findById(ch, id);
    if (item) return item;
  }

  return null;
}

export function unselectAll(tree) {
  if (tree) tree.checkStatus = 0;

  for (const ch of tree.children || []) {
    unselectAll(ch);
  }
}

export function treeToList(tree, isListView) {
  if (isListView) {
    return tree.children || [];
  }

  const result = tree.level === 0 ? [] : [tree];
  if ((tree.level === 0 || tree.isOpen) && tree.children) {
    for (const child of tree.children) {
      result.push(...treeToList(child));
    }
  }
  return result;
}

export function getParentCheckStatus(parent) {
  const { children = [] } = parent;
  const checkCount = children.filter((item) => item.checkStatus === 1).length;

  if (checkCount === 0) return 0;
  if (checkCount === children.length) return 1;
  return 2;
}

export function handleFileClick(file, teamId, originFileType, isNewWindow, idx) {
  if (originFileType === MY_COOPER) {
    window.__OmegaEvent('ep_personal_visit_ck', '', {
      resourceid: file.id,
      resource_id: file.id,
      position: idx,
      platform: 'new',
      type: FILETYPE[file.type],
      relationTypeTags: file.relationTypeTags,
    });
    // __mirrorSendEvent('MY_COOPER_LIST_NAME');
  } else {
    window.__OmegaEvent('ep_team_visit_ck', '', {
      resourceid: file.id,
      resource_id: file.id,
      position: idx,
      platform: 'new',
      type: FILETYPE[file.type],
      relationTypeTags: file.relationTypeTags,
    });
    // __mirrorSendEvent('TEAM_OPERATION_NAME');
  }
  let params = null
  switch (file.type) {
    // 普通文件
    case 0:
      // 对于预览此时需要多处理一下图片的上一页下一页的功能
      params = {
        resourceId: file.id,
        resourceName: file.name,
        shareId: 0,
        shareLink: '',
        markedQuickVisit: file.marked_quick_visit,
        objectType: file.space_resource_type,
        sourceId: file.teamId,
        quickId: file.quick_id || file.quickId,
        filesList: file?.parent?.children || [file],
        fidx: file?.parent?.children?.indexOf(file) || 0,
        relationTypeTags: file.relationTypeTags,
        teamId,
      }

      previewFile(params);
      break;

    // 文件夹
    case 1:
      params = {
        resourceId: file.id,
        teamId,
        spaceType: file.spaceType,
        isNewWindow,
      }
      openFolder(params);
      break;

    // 协作文档
    case 2:
      openCollob(file.id, file.mimeType, file.space_resource_type);
      break;

    // 氢文档
    case 3:
      openDidoc(file.id);
      break;

    case 4:
      openFlowChart(file.id);
      break;

    default:
      break;
  }
}

export async function drageMoveFile(items, target) {
  function _isChild(_item, _target) {
    let p = _item.parent;
    while (p) {
      if (p === _target) return true;
      p = p.parent;
    }
    return false;
  }

  if (items.some((it) => it.parent === target)) {
    message.warning(intl.t('请将文件移动至文件夹'));
    return;
  }

  if (items.some((it) => _isChild(target, it))) {
    message.warning(intl.t('不能移动父目录至子目录下'));
    return;
  }

  const { teamId } = target;
  const link = teamId ? `/team-file/${teamId}/${target.id}` : `/files/${target.id}`;


  await doMove(items.map((it) => it.id), teamId, target.id, teamId).then(() => {
    showProgress(MOVE, 0, items.length, link);
  }).catch((err) => {
    if (err.errorCode === 501082) {
      message.error(intl.t('您没有权限! 请联系空间管理员添加目标位置的编辑/上传权限。'));
    } else {
      message.error(err.message);
    }
  })
}
