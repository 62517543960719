/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-09-06 15:23:45
 * @LastEditTime: 2024-05-06 22:37:55
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperOperation/ImportCollab/index.js
 *
 */
import TextOnlyModal from '@/components/TextOnlyModal';
import { FileType } from '@/constants/cooper';
import { createImportTask, getImportParams, getImportProgress } from '@/service/cooper/upload';
import { isDC } from '@/utils/index';
import miniBus from '@/utils/miniBus';
import { message } from 'antd';
import axios from 'axios';
import { getLocale, intl } from 'di18n-react';
import showImportTip from '../CreateCollab/showImportTip';


const API = (region) => {
  if (process.env.APP_ENV === 'prod') {
    if (region === 2) {
      return 'https://cooper-external.didichuxing.com/sdk/v2/api/files/v1/import';
    }
    return 'https://cooper.didichuxing.com/sdk/v2/api/files/v1/import';
  }
  if (region === 2) {
    return 'https://sdk312.intra.xiaojukeji.com/sdk/v2/api/files/v1/import';
  }
  // return 'https://shimo2.intra.xiaojukeji.com/sdk/v2/api/files/v1/import';
  return 'https://sdk312.intra.xiaojukeji.com/sdk/v2/api/files/v1/import';
}
// fileId, type, file: readerFile
const createImportShimo2 = async (origin, url = '', data = {}) => {
  let myHeaders = new Headers();
  myHeaders.append('Content-Type', 'multipart/form-data');
  const { fileId, type, file, fileName, fileUrl } = data
  let formData = new FormData();
  formData.append('fileId', fileId)
  formData.append('type', type)
  if (origin === 1) {
    formData.append('fileUrl', fileUrl)
    formData.append('fileName', fileName)
  } else {
    formData.append('file', file)
  }
  const config = {
    method: 'POST',
    headers: myHeaders,
    url,
    timeout: 600000,
    data: formData,
  }
  return axios(config)
}


const { SHIMO2_WORD, SHIMO2_EXCEL } = FileType;

async function ImportCollab(file, origin, parentId, teamId, callback) {
  let messageText = origin === 0 ? '导入' : '转换' // 文件来源，0 本地文件 1 cooper平台的文件
  if (!file) return
  // 判断文件格式
  if (!/\.docx$|\.doc$|\.md$|\.txt$|\.xlsx$|\.xls$|\.csv$|\.xlsm$/i.test(file.name)) {
    message.error(intl.t('选择的文件格式不正确'));
    return;
  }

  // 判断文件大小
  if (origin === 0 && file.size > 30 * 1024 * 1024) {
    // 弹出提示框
    TextOnlyModal.confirm({
      title: intl.t('导入文档大小不能超过30MB'),
      type: 'warn',
      isShowFooterCancel: false,
    });
    return;
  }

  // 展示导入弹窗
  const close = showImportTip(intl.t(`正在${messageText}，请耐心等待...`));

  const docMap = {
    [SHIMO2_WORD]: '/docs2/document/:id',
    [SHIMO2_EXCEL]: '/docs2/sheet/:id',
  };

  const bigType = /\.docx$|\.doc$|\.md$|\.txt$/i.test(file.name) ? SHIMO2_WORD : SHIMO2_EXCEL;
  let timer = null
  try {
    let params = {
      origin,
      spaceId: teamId,
      parentId,
      docType: bigType,
      fileName: file.name,
      resourceId: file.id,
    }
    // 获取导入在线文档所需参数
    const { appId, fileId, signature, token, type, fileUrl, parentId: parentIdParams, region } = await getImportParams(params)

    let url = `${API(region)}?appId=${appId}&token=${token}&signature=${signature}`

    createImportShimo2(origin, url, { origin, fileId, type, file, fileName: file.name, fileUrl }).then(async (res) => {
      const shimoTaskId = res.data.data.taskId
      let params = {
        origin,
        spaceId: file.teamId || teamId || 0,
        parentId: parentIdParams || parentId,
        docType: bigType,
        fileName: file.name,
        resourceId: file.id,
        fileId,
        taskId: shimoTaskId,
      }
      const { taskId: cooperTaskId } = shimoTaskId && await createImportTask(params)
      timer = setInterval(async () => {
        const { status, resourceId } = await getImportProgress(cooperTaskId, fileId)
        if (status) {
          if (status === 'COMPLETED') {
            close();
            message.success(intl.t(`${messageText}成功`))
            clearInterval(timer)
            const { pathname } = window.location;
            // 个人空间和团队空间
            if (/^\/disk$|^\/files\/|^\/team-file\//.test(pathname)) {
              miniBus.dispatchEvent('folderCreated');
            }
            if (isDC()) {
              window.open(docMap[bigType].replace(':id', resourceId));
            } else {
              window.location = docMap[bigType].replace(':id', resourceId);
            }
          }
          if (status === 'FAILED') {
            close();
            message.error(intl.t(`${messageText}失败`))
            clearInterval(timer)
          }
        } else {
          message.error(intl.t(`${messageText}失败`))
          clearInterval(timer)
        }
      }, 1000);
    }).catch((err) => {
      if (getLocale() === 'en-US') {
        message.error(intl.t(`${messageText}失败`))
      } else {
        message.error(err?.response?.data?.message)
      }
      close();
    })
  } catch (error) {
    close();
  } finally {
    if (callback) callback();
  }
}

export default ImportCollab;
